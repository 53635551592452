const Structures = function() {
    this.structures = [
        {
            'name': 'Raitaru',
            'mat': 1,           // mat-requirement-bonus%
            'man_time': 15,     // manufacturing-time-bonus%
            'fee': 3,           // Job-fee-bonus%
            'type': 'Engineering'
        },
        {
            'name': 'Azbel',
            'mat': 1,
            'man_time': 20,
            'fee': 4,
            'type': 'Engineering'
        },
        {
            'name': 'Sotiyo',
            'mat': 1,
            'man_time': 30,
            'fee': 5,
            'type': 'Engineering'
        },
        {
            'name': 'Athanor',
            'yield': 2,         // refining-yield-bonus%
            'react_time': 0,    // reaction-time-bonus%
            'type': 'Refinery'
        },
        {
            'name': 'Tatara',
            'baseYield': 50,
            'yield': 4,
            'react_time': 25,
            'type': 'Refinery'
        },
        {
            'name': 'NPC',
            'baseYield': 32,
            'yield': 0,
            'type': 'Refinery'
        },
        {
            'name': 'No Rig',
            'mod': 0,
            'type': 'Rig'
        },
        {
            'name': 'T1 Rig',
            'mod': 1,
            'type': 'Rig'
        },
        {
            'name': 'T2 Rig',
            'mod': 3,
            'type': 'Rig'
        }
    ]
};

Structures.prototype.getEngineering = function() {
    return this.structures.filter((s) => {
        return s.type == "Engineering"
    });
};

Structures.prototype.getRefinery = function() {
    return this.structures.filter((s) => {
        return s.type == "Refinery"
    })
};

Structures.prototype.getRig = function() {
    return this.structures.filter((s) => {
        return s.type == "Rig"
    })
};

Structures.prototype.getByName = function(name) {
    return this.structures.find(s => s.name == name);
};

export default (new Structures());

import axios from '../axios.js'
import constants from '../constants.js'

const getConsentCookie = function() {
    let cname = 'consent=';
    let decCookie = decodeURIComponent(document.cookie);
    let conCookie = decCookie.split(';').find((c) => {
        return c.trim().indexOf(cname) == 0;
    });
    if (!conCookie) return;
    return conCookie.substring(conCookie.indexOf("=")+1);
}

const calculateReadingTime = function(msg, detail) {
    let strg = msg + (detail?.error || detail || "");
    strg = strg.replace(/[^a-zA-Z0-9 ]/g, '');
    strg = strg.replace(/\s+/g, ' ');
    let rt = strg.split(' ').length / 200;
    console.log("ReadingTime: ", rt*60*1000);
    return rt * 60 * 1000;
}

const globalState = {
    namespaced: true,
    state: () => ({
        loading: false,
        loginLoading: false,
        messageCntr: 0,
        loadingStatus: 0,
        messages: [],
        items: {},
        cCnsnt: getConsentCookie() || ""
    }),
    mutations: {
        ADD_ITEM_LOOKUP (state, value) {
            state.items[value.id] = value;
        },
        loginLoading (state, value) {
            state.loginLoading = value;
        },
        addMessage (state, msg) {
            msg.id = state.messageCntr++;
            state.messages.push(msg);
        },
        removeMessage (state, msg) {
            state.messages.splice(state.messages.indexOf(msg), 1);
        },
        setLoadingStatus (state, val) {
            state.loadingStatus = val;
        },
        setConsentCookie (state, val) {
            state.cCnsnt = val;
        }
    },
    actions: {
        addMessage ({commit}, msg) {
            commit("addMessage", msg);
            if (msg.type !== "error") {
                setTimeout(() => {
                    commit("removeMessage", msg);
                }, calculateReadingTime(msg.message, msg.details) + 1000);
            } else {
                setTimeout(() => {
                    commit("removeMessage", msg);
                }, calculateReadingTime(msg.message, msg.details) + 3000);
            }
        },
        setLoading ({commit, state}, {which, bol}) {
            if (!constants[which]) {
                console.log("LoadingStatus", which, "unknown");
                return;
            }
            if (bol) {
                commit("setLoadingStatus", state.loadingStatus | constants[which]);
            } else {
                commit("setLoadingStatus", state.loadingStatus - (state.loadingStatus & constants[which]));
            }
        },
        lookupItem ({commit, state}, itemId) {
            return new Promise((resolve, reject) => {
                if (!itemId) {
                    reject("lookupItem without itemId");
                    return;
                }
                if (state.items[itemId]) {
                    resolve(state.items[itemId]);
                    return;
                }
                axios.get("market/item/" + itemId).then(function(result) {
                    commit("ADD_ITEM_LOOKUP", result.data);
                    resolve(result.data);
                }).catch((error) => {
                    reject(error);
                });
            })
        },
        setConsentCookie ({commit}, val) {
            var d = new Date();
            d.setDate(d.getDate() + 360);
            document.cookie = "consent=" + val + "; expires=" + d.toUTCString() + "; path=/";
            commit("setConsentCookie", val);
        }
    },
    getters: {

    }
}

export default globalState

import axios from "../axios.js"
import {EventBus} from '../eventBus.js'

const mgOpenGroupPath = function(arr, grpId) {
    let hasOpenGroup = false;
    for (var i in arr) {
        let curr = arr[i];
        if (mgOpenGroupPath(curr.childGroups, grpId)) {
            curr.open=true;
            hasOpenGroup=true;
//            return true;
        } else if (curr.id == grpId) {
            curr.open = true;
            hasOpenGroup=true;
//            return true;
        } else {
            curr.open = false;
        }
    }
    return hasOpenGroup;
};

const marketGroup = {
    namespaced: true,
    state: () => ({
        loading: false,
        marketGroups: [],
        selectedItem: {},
        items: {}
    }),
    mutations: {
        SAVE_MARKET_GROUPS(state, tree) {
            state.marketGroups = tree;
        },
        SAVE_SELECTED_ITEM(state, item) {
            state.selectedItem = item;
            mgOpenGroupPath(state.marketGroups, item.marketGroupId);
        },
        SAVE_OPEN_GROUP(state, grpId) {
            mgOpenGroupPath(state.marketGroups, grpId);
        },
        CACHE_ITEMS(state, dat) {
            state.items[dat.id] = dat.list;
        }
    },
    actions: {
        load ({state, commit, dispatch}) {
            return new Promise((resolve, reject) => {
                axios.get('market/groups').then(function(response) {
                    let data = response.data;
                    commit('SAVE_MARKET_GROUPS', data);
                    if (state.selectedItem && state.selectedItem.id) {
                        commit('SAVE_SELECTED_ITEM', state.selectedItem);
                    }
                    resolve(true);
                }).catch(function(error) {
                    reject(error);
                });
            });
        },
        selectItem ({commit, dispatch}, item) {
            commit('SAVE_SELECTED_ITEM', item);
            EventBus.emit('MarketGroupItemSelected', item);
        },
        openItemInTree ({commit, dispatch}, item) {
            commit('SAVE_SELECTED_ITEM', item);
        },
        openGroup({commit, dispatch}, grpId) {
            commit('SAVE_OPEN_GROUP', grpId);
        },
        cacheItems({commit}, dat) {
            commit("CACHE_ITEMS", dat);
        }
    }
}

export default marketGroup

import { nextTick } from 'vue'
import { createRouter, createWebHistory } from 'vue-router'

import { routes } from './routes.js'

const router = createRouter({
  history: createWebHistory(),
  routes
});

router.afterEach((to, from) => {
    nextTick(() => {
        document.title = to.name ? to.name + " - EVE.rything" : "EVE.rything"
    });
});

export default router

<template>
  <div :class="{hasHover: hasHover}">
      <TheMessages />
      <div id="nav">
          <span id="logo" @click="routeTo({ path: '/'})">
              <SvgAtom :name="'logo.svg'" />
              <div class="version">Version: {{ version }}</div>
          </span>
          <div class="links">
              <!-- <router-link to="/" active-class="nope">Home</router-link> -->
              <router-link to="/market">Market</router-link>
              <router-link to="/appraisal">Appraisal</router-link>
              <router-link to="/prodPlan">Production</router-link>
              <router-link to="/assets" v-if="isLoggedIn">Containersharing</router-link>
              <span v-else class="placeholder" title="Register for Access">Containersharing</span>
              <router-link to="/navigation" v-if="isLoggedIn">Navigation</router-link>
              <span v-else class="placeholder" title="Register for Access">Navigation</span>
              <router-link to="/incursions">Incursions</router-link>
              <!-- <span v-else class="placeholder" title="Register for Access">Incursions</span> -->
<!--              <router-link to="/bargain" v-if="isLoggedIn">Bargain</router-link> -->
          </div>
      </div>
      <router-link to="/settings" class="settings"><SvgAtom name="settings.svg" /></router-link>
      <TheCharacters v-if="cCnsnt && cCnsnt.indexOf('functional') >= 0" />
      <router-view class="ruv" />
      <div class="tlsp" v-if="loading">
          <TheLoadingSpinner />
      </div>
      <div id="legal">
          <span><a href="#" @click.stop.prevent="disclaimerOpen=!disclaimerOpen">Disclaimer</a></span>
          <span><a href="#" @click.stop.prevent="cookieBannerOpen=true">Cookie settings</a></span>
          <span><a href="#" @click.stop.prevent="impressumOpen=!impressumOpen">Impressum</a></span>
      </div>
      <div id="disclaimer" class="infoBox" v-if="disclaimerOpen">
          <h3>General disclaimer</h3>
          <p>The content of this site is being provided freely, and no kind of agreement or contract is created between you and the owners or users of this site, the owners of the servers upon which it is housed, individual contributors to these pages, or project administrators, sysops or anyone else connected with this project subject to your claims against them directly.</p>
          <p>Any of the trademarks, service marks, collective marks, design rights, personality rights or similar rights that are mentioned, used or cited on this site are the property of their respective owners.</p>
          <h3>CCP Copyright Notice</h3>
          <p><a href="https://www.eveonline.com/">EVE Online</a> and the EVE logo are the registered trademarks of <a href="https://www.ccpgames.com/">CCP hf.</a> All rights are reserved worldwide. All other trademarks are the property of their respective owners. EVE Online, the EVE logo, EVE and all associated logos and designs are the intellectual property of CCP hf. All artwork, screenshots, characters, vehicles, storylines, world facts or other recognizable features of the intellectual property relating to these trademarks are likewise the intellectual property of CCP hf. CCP hf. has granted permission to EVE University to use EVE Online and all associated logos and designs for promotional and information purposes on its website but does not endorse, and is not in any way affiliated with, EVE University. CCP is in no way responsible for the content on or functioning of this website, nor can it be liable for any damage arising from the use of this website.</p>
          <h3>Pexels</h3>
          <p>Some images are provided by <a href="pexels.com/creative-commons-images/">Pexels</a>. A real tresure for images/photos of all kinds.</p>
      </div>
      <div id="impressum" class="infoBox" v-if="impressumOpen">
          <h3>Offenlegung gemäß § 25 Mediengesetz:</h3>
          <p>Medieninhaber und für den Inhalt verantwortlich:</p>
          <p>Manfred Andres, 2462 Wilfleinsdorf</p>
          <h3>Grundlegende Richtung:</h3>
          <p>Freies Bereitstellen von Informationen aus dem Online Videospiel EVE Online.</p>
      </div>
      <div id="cookieBanner" v-if="cCnsnt.length < 1 || cookieBannerOpen">
            <div>
                Hey! Let me enhance your experience on this site.<br />I promise no cooky will be harmed ;)<br />(We don't sell your Data)
            </div>
            <span><!--astronaut--></span>
            <span class="wrapper"><span class="ra">Functional cookies</span><input name="functional" type="checkbox" :checked="cCnsnt.indexOf('functional') > -1" /><span /></span>
            <span class="wrapper"><span class="ra">3rd Party</span><input name="3rdParty" type="checkbox" :checked="cCnsnt.indexOf('3rdParty') > -1" /><span /></span>
            <button @click="denyAll">Deny all</button><button @click="save">Save</button><button @click="acceptAll">Accept all</button>
      </div>
      <a v-if="cCnsnt.indexOf('3rdParty') >= 0" class="bmc-button" target="_blank" href="https://www.buymeacoffee.com/korashun">
          <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 884 1279"><path d="m791 298-1-1-2-1 3 2Zm13 91h-1 1Zm-13-92v1-1Zm0 1v-1 1Zm12 91 2-1 1-1-3 2Zm-9-89-2-2h-1l3 2Zm-364 886-3 2h1l2-2Zm211-41v3-3Zm-22 41-3 2h1l2-2Zm-338 10-3-1 3 1Zm-33-32-1-4 1 4Z" fill="#0D0C22"/><path d="M473 591c-46 20-98 42-166 42-28 0-56-4-84-12l47 480a80 80 0 0 0 80 74l88 3 96-3a80 80 0 0 0 79-74l50-530c-22-8-44-13-70-13-44 0-79 15-120 33Z" fill="#FD0"/><path d="M79 386v1h1l-1-1Z" fill="#0D0C22"/><path d="m880 342-7-36c-7-31-21-62-54-73-10-4-22-5-30-13s-11-19-12-30l-11-61c-3-17-5-37-13-52-10-22-32-34-53-42-11-5-22-8-33-11C613 10 557 5 503 2c-66-4-132-2-198 3-48 5-100 10-146 27-17 6-35 14-47 27a51 51 0 0 0-10 61c8 14 22 24 37 31 19 8 39 15 60 19a1334 1334 0 0 0 417 7c19-3 31-27 25-44-6-21-24-28-44-25l-9 1h-2a1234 1234 0 0 1-313 1l-6-1h-1l-6-1a665 665 0 0 1-42-9 6 6 0 0 1 3-9 626 626 0 0 1 45-8l21-2a1336 1336 0 0 1 290 1l7 1h5l43 8c21 5 47 6 57 29 3 7 4 15 6 23l2 10a153589 153589 0 0 0 15 74 13 13 0 0 1-11 10l-3 1h-3a1670 1670 0 0 1-253 16 1976 1976 0 0 1-250-16l-7-1-15-3-52-8c-21-4-41-2-60 8-16 9-28 22-36 38-8 17-11 35-14 53-4 18-10 38-8 56 5 40 33 73 74 80a2072 2072 0 0 0 604 20 26 26 0 0 1 28 29l-3 37a15200479 15200479 0 0 1-55 528c-2 22-2 44-6 66-7 34-30 54-63 62-31 7-62 11-94 11l-105-1c-37 0-82-3-111-31-26-24-29-63-32-96l-14-131-25-242-17-157-1-8c-2-19-15-37-36-36-18 0-38 16-36 36l12 116 25 241 22 205 4 40c8 71 62 110 130 121 40 6 80 8 120 8 52 1 104 3 155-6 75-14 131-64 139-142a758975 758975 0 0 0 30-290l25-243 11-111a26 26 0 0 1 21-22c21-5 42-12 57-28 24-26 29-60 21-94ZM72 366v4-4Zm3 16 1 1-2-1h1Zm2 3c0 1 1 2 0 0Zm4 3Zm720-5c-8 7-20 11-31 12a2172 2172 0 0 1-667-2c-9-1-19-3-25-9-12-13-6-38-3-53 3-14 8-33 25-35 26-3 56 8 81 12a1787 1787 0 0 0 562-7c22-4 45-11 58 11 9 15 10 35 9 52-1 7-4 14-9 19Z" fill="#0D0C22"/></svg>
          <span>By me a coffee</span>
      </a>
  </div>
</template>

<script>
import TheMessages from './components/TheMessages.vue'
import TheCharacters from './components/TheCharacters.vue'
import {EventBus} from './eventBus.js'
import TheLoadingSpinner from './components/TheLoadingSpinner.vue'
import version from './version.js'
import { mapState, mapGetters } from 'vuex'
import SvgAtom from './components/atoms/SvgAtom.vue'

export default {
  name: 'App',
  data() {
      return {
          lastTouchTime: 0,
          hasHover: true,
          version: version,
          cookieBannerOpen: false,
          impressumOpen: false,
          disclaimerOpen: false
      }
  },
  computed: {
      ...mapGetters(['isLoggedIn']),
      loading() {
          return this.$store.state.globalState.loadingStatus != 0;
      },
      cCnsnt() {
          return this.$store.state.globalState.cCnsnt;
      }
  },
  components: {
    TheCharacters,
    TheMessages,
    TheLoadingSpinner,
    SvgAtom,
  },
  methods: {
    routeTo(dest) {
        this.$router.push(dest);
    },
    denyAll() {
        this.$store.dispatch("globalState/setConsentCookie", 'denyAll');
        this.cookieBannerOpen=false;
    },
    acceptAll() {
        this.$store.dispatch("globalState/setConsentCookie", 'functional,3rdParty');
        this.cookieBannerOpen=false;
    },
    save() {
        var arr = [];
        var cb = document.getElementById("cookieBanner").getElementsByTagName("input");
        for (var i = 0; i < cb.length; i++) {
            var curr = cb[i];
            if (curr.checked) {
                arr.push(curr.name);
            }
        }
        this.$store.dispatch("globalState/setConsentCookie", arr.length < 1 ? "denyAll" : arr.join(","));
        this.cookieBannerOpen=false;
    }
  },
  mounted() {
  },
  created() {
    document.addEventListener('click', (ev) => {
        EventBus.emit("EmptyClick", ev);
    });
  }
}
</script>

<style lang="scss">
@import "@/scss/_variables.scss";

// general css
body {
    margin: 164px 0 18px 0;
    padding: 0 2px;
    background-color: $c-background;
    max-width: 1920px;
}

#nav {
    position: fixed;
    z-index: 99;
    top: 0px;
    left: 0px;
    right: 0px;
    background-image: url(./assets/pexels-pixabay-2150.webp);

    picture {
        display: inline-block;
    }
    .links {
        position: absolute;
        bottom: -30px;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        left: 20px;
        a, .placeholder {
            z-index: 100;
            background-color: $c-light-border;
            text-align: center;
            border-radius: 20px 20px 0 0;
            border-color: $c-light-border;
            border-style: solid;
            border-width: 2px 2px 0 2px;
            text-decoration: none;
            color: $c-background;
            padding: 8px 2px;
        }
        a:nth-child(n+4),
        .placeholder:nth-child(n+4) {
            margin-top: 1px;
            border-radius: 0 0 20px 20px;
        }

        a.router-link-exact-active,
        a.router-link-active {
            background-color: $c-background;
            color: $c-light-border;
        }
        .placeholder {
            opacity: .3;
            text-decoration: line-through;
        }
        font-size: 0.7em;
    }
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: $c-font;
}

a {
    color: #99b;
}

.sec10, a.sec10, li.sec10>a { color: #657792 !important; }
.sec09, a.sec09, li.sec09>a { color: #389cf3 !important; }
.sec08, a.sec08, li.sec08>a { color: #4acff0 !important; }
.sec07, a.sec07, li.sec07>a { color: #5ddca6 !important; }
.sec06, a.sec06, li.sec06>a { color: #71e452 !important; }
.sec05, a.sec05, li.sec05>a { color: #eeff83 !important; }
.sec04, a.sec04, li.sec04>a { color: #dc6c09 !important; }
.sec03, a.sec03, li.sec03>a { color: #d1440d !important; }
.sec02, a.sec02, li.sec02>a { color: #bc1114 !important; }
.sec01, a.sec01, li.sec01>a { color: #6d2124 !important; }
.sec00, a.sec00, li.sec00>a { color: #8f2f69 !important; }

.settings {
    svg {
        transition: right .4s;
        z-index: 99;
        position: fixed;
        right: 100px;
        top: 5px;
        height: 25px;
        width: 25px;
        path {
            fill: $c-highlight;
        }
    }
}

.currency {
    padding-left: 5px;
}

.ra {
    text-align: right;
}

select {
    background-color: #ccc;
}

.small {
    font-size: 8px;
}

.smaller {
    font-size: 0.7em;
}

.arr_left>svg {
    transform: rotate(90deg);
    height: 20px !important;
    width: 20px !important;
    vertical-align: middle;
}

.arr_up>svg {
    transform: rotate(180deg);
    height: 20px !important;
    width: 20px !important;
    vertical-align: middle;
}

#legal {
    position: fixed;
    bottom: 0;
    left: 0;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    width: 100vw;
    background-color: $c-light-box;
    text-align: center;
    padding: 2px;
    z-index: 100;
    a {
        text-decoration: none;
    }
}

#disclaimer {
    bottom: 25px;
    left: 5px;
    z-index: 199;
}

#impressum {
    bottom: 25px;
    right: 5px;
    z-index: 199;
}

#logo {
    cursor: pointer;
    svg {
        height: 110px;
        fill: $c-border;
        position: absolute;
        top: -5px;
        left: 30px;
    }
    .version {
        position: fixed;
        top: 0;
        left: 200px;
        z-index: 100;
        color: $c-light-box;
        font-size: 0.8em;
        display: none;
    }
}

#logo:hover .version {
    display: inline-block;
}

a.bmc-button {
    display: grid;
    background: #fff;
    width: 25px;
    transition: width .3s, opacity .3s;
    grid-template-columns: 25px 105px;
    gap: 8px;
    border-radius: 20px;
    text-decoration: none;
    font-family: Helvetica;
    align-items: center;
    position: fixed;
    bottom: 10px;
    right: 10px;
    overflow: hidden;
    padding: 5px;
    z-index: 100;
    opacity: .5;
}
a.bmc-button:hover {
    width: 130px;
    opacity: 1;
}

#contact {
    color: #fff;
    display: grid;
    background-color: #333;
    position: relative;
    input {
        background-color: #333;
        color: #fff;
        font-size: 1.2rem;
        height: 1.2rem;
        margin: 3px;
        border: 1px dashed #767676;
    }
    textarea {
        background-color: #333;
        color: #fff;
        height: 25vh;
    }
    .sbtn {
        text-align: right;
    }
    button {
        height: 48px;
        position: relative;
        right: 0px;
    }
}

.ps1 {
    color: #fff;
}
.ps2 {
    color: #fdd;
}
.ps3 {
    color: #fbb;
}
.ps4 {
    color: #f99;
}
.ps5 {
    color: #f77;
}
.ps6 {
    color: #f44;
}
.ps7 {
    color: #f00;
}


.scb {
    scrollbar-color: $c-border $c-light-box;
    scrollbar-width: thin;
}

.scb::-webkit-scrollbar {
    width: 10px;
}
.scb::-webkit-scrollbar-track {
    border-radius: 10px;
    background: $c-light-box;
}
.scb::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: $c-border;
}

.ca {
    cursor: pointer;
}

button {
    transition: background-color .3s, border-color .3s, color .3s;
    -webkit-transition: background-color .3s, border-color .3s, color .3s;
    -o-transition: background-color .3s, border-color .3s, color .3s;
    -moz-transition: background-color .3s, border-color .3s, color .3s;
    border: 5px solid $c-border;
    font-size: 1.1rem;
    background-color: $c-highlight;
    color: #ccc;
    cursor: pointer;
    &.selected {
        background-color: #ccc;
        border-color: #ccc !important;
        color: #000;
    }
}
button:first-child {
    border-top-left-radius: 30px;
    border-bottom-left-radius: 30px;

}
button:last-child {
    border-top-right-radius: 30px;
    border-bottom-right-radius: 30px;
}

.tlsp {
    height: 250px;
    width: 250px;
    position: fixed;
    top: 25vh;
    left: 25vw;
    z-index: 199;
}


/* --------------------------------------------------- RESOLUTIONS -----------------------------------------------*/

@media only screen and (max-width: 415px) {
    .settings {
        svg {
            height: 30px;
            width: 30px;
        }
    }
    #logo {
        svg {
            top: 25px;
            height: 80px;
        }
    }
    #nav {
        height: 131px;
        picture>* {
            height: 131px;
        }
        .links {
            font-size: 0.7em;
            z-index: 199;
        }
    }
    #app #cookieBanner {
        background-size: 280px;
        background-position: 230px 90px;
        display: block;
        width: calc(90vw - 30px);
        >div {
            display: inline-block;
            br { content: ""; }
            br:after { content: " "; }
        }
        input {
            height: 1.5em;
            width: 1.5em
        }
        .wrapper {
            font-size: 1.5em;
            text-align: center;
            display: block;
        }
    }
}
/*
<div id="cookieBanner" v-if="cCnsnt.length < 1 || cookieBannerOpen">
            <div>
                Hey! Let me enhance your experience on this site.<br />I promise no cooky will be harmed ;)<br />(We don't sell your Data)
            </div>
            <span><!--astronaut--></span>
            <span class="wrapper"><span class="ra">Functional cookies</span><input name="functional" type="checkbox" :checked="cCnsnt.indexOf('functional') > -1" /><span /></span>
            <span class="wrapper"><span class="ra">3rd Party</span><input name="3rdParty" type="checkbox" :checked="cCnsnt.indexOf('3rdParty') > -1" /><span /></span>
            <button @click="denyAll">Deny all</button><button @click="save">Save</button><button @click="acceptAll">Accept all</button>
      </div>
*/

@media only screen and (min-width: 415px) {
    #nav {
        height: 131px;
        picture>* {
            height: 131px;
        }
    }
}

@media screen and (min-width: 570px) {
    body {
        margin-top: 140px;
    }
    #nav .links {
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
        bottom: 0;
        font-size: 1em;
        a:nth-child(n+4),
        .placeholder:nth-child(n+4) {
            margin-top: 0;
            border-radius: 20px 20px 0 0;
        }
        a {
            padding: 8px 8px 3px 8px;
        }
    }
}



@media screen and (min-width: 1090px) {
    .settings {
        svg {
            right: 210px;
        }
    }
}

//@media screen and (min-width: 1280px) {



input {
    background-color: #ccc;
    height: 1.7em;
    border: 0;

}


button {
    transition: background-color .3s, border-color .3s, color .3s;
    -webkit-transition: background-color .3s, border-color .3s, color .3s;
    -o-transition: background-color .3s, border-color .3s, color .3s;
    -moz-transition: background-color .3s, border-color .3s, color .3s;
    border: 5px solid $c-border;
    font-size: 1.1rem;
    background-color: $c-highlight;
    color: #ccc;
    cursor: pointer;
    &.selected {
        background-color: #ccc;
        border-color: #ccc !important;
        color: #000;
    }
}
button:first-child {
    border-top-left-radius: 30px;
    border-bottom-left-radius: 30px;

}
button:last-child {
    border-top-right-radius: 30px;
    border-bottom-right-radius: 30px;
}



.NaN {
    color: #a33;
    font-weight: bold;
    font-size: 1.1rem;
}

.infoBox {
    font-size: 0.8em;
    border: 3px solid $c-border;
    border-radius: 20px;
    display: inline-block;
    width: 50vw;
    position: absolute;
    padding: 10px;
    background-color: $c-background;
}



#cookieBanner {
    display: grid;
    gap: 3px;
    grid-template-columns: 1fr 1fr 1fr;
    background-image: url('/public/img/astronaut.svg');
    background-position: 250px -70px;
    background-repeat: no-repeat;
    background-color: #333;
    padding: 20px;
    border-radius: 50px;
    position: fixed;
    z-index: 199;
    width: 600px;
    height: 159px;
    bottom: 5px;
    right: 5px;
    -webkit-box-shadow: 20px 20px 50px 0px grey;
    box-shadow: 0px -10px 30px 25px #497f9b;
    border: 10px solid #497f9b;
    button:nth-last-child(3) {
        border-top-left-radius: 30px;
        border-bottom-left-radius: 30px;
    }
    div {
        grid-column-start: 1;
        grid-column-end: 3;
        text-align: center;
    }
    .wrapper {
        display: contents;
    }
}


.infotext {
    position:fixed;
    width: 70vw;
    background-color: $c-background;
    z-index: 199;
    left: 250px;
    top: 180px;
    border: 5px solid $c-border;
    border-radius: 15px;
    padding: 0 15px;
    box-shadow: 0px 0px 22px 0px #000000;
    max-height: calc(100vh - 280px);
    overflow-y: auto;
}

.ibright {
    top: 5px;
    right: 10px;
}

.info {
    height: 20px;
    display: inline-block;
    position: absolute;
    cursor: pointer;
    svg {
        display: inline-block;
        height: 100%;
    }
}

.intro {
    display: block;
    max-width: 600px;
    padding: 5px;
    border-left: 5px solid $c-light-box;
    border-radius: 15px;
}
.conButton {
    text-align: center;
    padding: 0;
    margin: 5px;
    button {
        border: 5px solid $c-border;
    }
    span {
        margin: 0 5px 5px 5px;
    }
}

.halt {
    position: relative;
    cursor: help;
    span {
        border: 2px solid $c-border;
        border-radius: 15px;
        padding: 5px;
        display: none;
        position: absolute;
        top: 20px;
        left: 0;
        background-color: $c-background;
    }
}
.halt.ra {
    span {
        right: 0;
        left: inherit;
    }
}
.halt:hover {
    span {
        display: inline-block;
    }
}

*:focus {
    outline-style: dotted;
    outline-color: #497f9b;
    outline-offset: 2px;
    outline-width: 2px;
}

//}
</style>

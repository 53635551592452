import { createStore } from 'vuex'

/**
 * the esi-authenticated characters will be handled here
 */
import characters from './characters.js'

/**
 * global states like "loading" or other rendering-related globals
 */
import globalState from './global_state.js'

/**
 * Market group information
 */
import marketGroup from './market_group.js'

/**
 * Market orders
 */
import order from './order.js'

/**
 * Production cost substore
 */
import productionCost from './production_cost.js'

/**
 * Production planer substore
 */
import productionPlan from './production_planer.js'

/**
 * Settings for diverse stuff like material-efficiency, stations, ..
 */
import settings from './settings.js'

/**
 * Appraisal data
 */
import appraisal from './appraisal.js'

/**
 * Assets data
 */
import assets from './assets.js'

/**
 * Shop data
 */
import shop from './shop.js'

/**
 * SVG store
 */
import svg from './svg.js'

const isLoggedIn = function (state) {
    return !!state.characters.main &&
        state.globalState.cCnsnt.indexOf("functional") >= 0;
};

const store = createStore({
    modules: {
        characters,
        globalState,
        marketGroup,
        order,
        productionCost,
        productionPlan,
        settings,
        appraisal,
        svg,
        assets,
        shop
    },
    getters: {
        isLoggedIn,
        activeCharacter(state) {
            if (!isLoggedIn(state)) return;
            return state.characters.all.filter((c) => {
                return c.id == state.characters.main;
            })[0];
        }
    }
});

store.watch((state) => state.characters.main, (val) => {
    store.dispatch('characters/notifyListeners');
})

export default store;

const navigationKeys = [
    "ArrowDown",
    "ArrowLeft",
    "ArrowRight",
    "ArrowUp",
    "End",
    "Home",
    "PageDown",
    "PageUp"
];

const metaKeys = [
    "Alt",
    "AltGraph",
    "CapsLock",
    "Control",
    "Fn",
    "FnLock",
    "Hyper",
    "Meta",
    "NumLock",
    "ScrollLock",
    "Shift",
    "Super",
    "Symbol",
    "SymbolLock"
];

const editingKeys = [
    "Backspace",
    "Clear",
    "Copy",
    "CrSel",
    "Cut",
    "Delete",
    "EraseEof",
    "ExSel",
    "Insert",
    "Paste",
    "Redo",
    "Undo"
];

export default {
    'MarketTree': 1,
    'Orders': 2,
    'Appraisal': 4,
    'Schnaeppchen': 8,
    'ProductionCost': 16,
    'LoadAssets': 32,
    'Calculate': 64,
    'Assets': 128,
    'keys': {
        'navigation': [...navigationKeys],
        'editing': [...editingKeys],
        'meta': [...metaKeys],
        'noneWriting': [...navigationKeys, ...metaKeys]
    }
}



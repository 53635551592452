import axios from '../axios.js'
import {propByPath, objectAsArray} from '../util.js'
import {calculateMEmats, manufacturingJobCost} from '../formulas.js'

const setProduce = function(el, val) {
    if (!el.m) {
        return;
    }
    el.produce = val;
    el.m.forEach(function(sel) {
        setProduce(sel, val);
    });
}

const productionCost = {
    namespaced: true,
    state: () => ({
        item: {},
        conf: {
            runs: 1,
            region: 'Jita'
        },
        ssn: "Amarr", // Solar System Name
        ci: { // cost indices
            manufacturing: 0,
            researching_time_efficiency: 0,
            researching_material_efficiency: 0,
            copying: 0,
            invention: 0,
            reaction: 0
        },
        p: { }, // price-lookup-table
        bp: {
           i: {}, // used blueprint
           type: 1, // 1 = manufacturing, 5 = reaction
           t: 0, // time to produce
           mp: 0, // max production limit
           q: 0, // produced quantity
           f: {}, // invented from this blueprint
           fm: [] // invented using these materials
        },
        i: {}, // produced item
        m: [], // produced using these materials
        produce: true,
        available: {},
        calculation: {},
        calculated: false
    }),
    mutations: {
        SAVE_PRODUCTION_PLAN(state, {ssn, ci, p, bp, i, m, calculated}) {
            m.forEach((mat) => {
                setProduce(mat, false);
            });
            state.ssn = ssn;
            state.ci = ci;
            state.p = p;
            state.bp = bp;
            state.i = i;
            state.m = m;
            state.calculated = calculated;
            state.produce = true;
        },
        SAVE_ITEM(state, item) {
            state.item = item;
        },
        SAVE_AVAILABLE(state, available) {
            state.available = available;
        },
        UPDATE_SYSTEM_AND_COST_INDEX(state, {sc, sn}) {
            state.ssn = sn;
            state.ci = sc;
        },
        SAVE_CALCULATION(state, calc) {
            state.calculation = calc;
            state.calculated = true;
        },
        SET_CALCULATED(state) {
            state.calculated = true;
        },
        SET_PRODUCE_FOR_PATH(state, {val, path}) {
            var curr = state;
            var key;
            var p = [...path];
            while ((key = p.shift()) != undefined) {
                curr = curr.m[key];
            }
            curr.produce = val;
        }
    },
    actions: {
        loadSCI ({commit, dispatch}, val) {
            return new Promise((resolve, reject) => {
                axios.get('industry/costIndices/' + val.scid).then(function(result) {
                    let data = result.data;
                    data.name = val.name;
                    commit('UPDATE_SYSTEM_AND_COST_INDEX', {sc: data, sn: val.name});
                    dispatch('calculate');
                    resolve(data);
                }, (error) => {
                    reject(error);
                })
            })
        },
        load ({commit, dispatch}, item) {
            if (this.item && item.id == this.item.id) {
                return;
            }
            dispatch('globalState/setLoading', {which: "ProductionCost", bol: true}, {root: true});
            axios.get('industry/production/cost/' + item.id).then(result => {
                commit('SAVE_ITEM', item);
                commit('SAVE_PRODUCTION_PLAN', result.data);
                dispatch("loadAssetsAndBlueprints");
            }).catch((error) => {
                if (error.response) {
                    if (error.response.status == 404) {
                        dispatch("globalState/addMessage", {
                            type: "error",
                            message: "Item not found or unproduceable",
                            details: error
                        }, { root: true });
                    }
                    dispatch("globalState/addMessage", {
                        type: "error",
                        message: "Server responded with status-code " + error.response.status,
                        details: error
                    }, { root: true });
                } else if (error.request) {
                    dispatch("globalState/addMessage", {
                        type: "error",
                        message: "No response from Server",
                        details: error
                    }, { root: true });
                } else {
                    dispatch("globalState/addMessage", {
                        type: "error",
                        message: "Failed axios request",
                        details: error
                    }, { root: true });
                }
            }).then(() => {
                dispatch('globalState/setLoading', {which: "ProductionCost", bol: false}, {root: true});
            });
        },
        loadAssetsAndBlueprints({commit, rootState, state, dispatch}) {
            if (!rootState.characters.main ||
                 rootState.globalState.cCnsnt.indexOf("functional") < 0) {
                dispatch("calculate");
                return;
            }
            dispatch('globalState/setLoading', {which: "LoadAssets", bol: true}, {root: true});
            let dat = getRecursiveAssetsAndBlueprints(state, {bp: [], a: []});
            axios.post("industry/assetsAndBlueprints/", {
                blueprintIds: dat.bp,
                assetIds: dat.a
            }).then(result => {
                commit("SAVE_AVAILABLE", result.data);
                availableSumCache = undefined;
                dispatch('globalState/setLoading', {which: "LoadAssets", bol: false}, {root: true});
                dispatch("calculate");
            });
        },
        calculate({commit, dispatch, state, rootState}) {
            dispatch('globalState/setLoading', {which: "Calculate", bol: true}, {root: true});
            try {
                settings = rootState.settings;
                let d = Date.now();
                let calc = calculate.call(state, state, {}, {
                    path: [],
                    needed: state.conf.runs
                }, availableSum(state.available));
                calc.available = 0;
                console.log("calculated in", Date.now()-d, 'millis');
                d = Date.now();
                commit('SAVE_CALCULATION', calc);
                console.log("committed in", Date.now()-d, 'millis');
            } catch(e) {
                console.log("calculation failed", e);
            } finally {
                dispatch('globalState/setLoading', {which: "Calculate", bol: false}, {root: true});
            }
        }
    }
}
var settings, availableSumCache;

const availableSum = function(avPerChar) {
    let available = {};
    objectAsArray(avPerChar).forEach((chr) => {
        if (isNaN(chr.key)) return;
        chr.data.a.forEach((a) => {
            if (available[a.t] == undefined) {
                available[a.t]=a.q;
            } else {
                available[a.t]+=a.q;
            }
        })
        chr.data.b.forEach((a) => {
            if (available[a.id] == undefined) {
                available[a.id]=[{
                    ME: a.me,
                    TE: a.te,
                    r: a.r,
                    chr: chr.key
                }];
            } else {
                available[a.id].push({
                    ME: a.me,
                    TE: a.te,
                    r: a.r,
                    chr: chr.key
                });
            }
            available[a.id].t = a.t;
        })
    })
    return available;
}

const calculate = function(host, ctree, stepInfo, available) {
    var calc = ctree;
    calc.buy = stepInfo.needed * propByPath(this.p, [host.i.id, 'sell', this.conf.region, 'min']);
    calc.needed = stepInfo.needed;
    var qavail = available[host.i.id];
    if (qavail && qavail > 0) {
        if (qavail >= calc.needed) {
            calc.available = calc.needed;
            qavail -= calc.needed;
        } else {
            calc.available = qavail;
            qavail = 0;
        }
    }
    if (!host.m) {
        // only get the buy-price and store the needed quantity

    } else {
        let isReaction = host.bp.type == 5;
        // calculate the needed runs for this
        let neededRuns = Math.ceil(stepInfo.needed / host.bp.q);

        // get best blueprints
        let meArr = getMEArray(available, host.bp.i.id, neededRuns, isReaction);
        let bp = meArr.find((el) => {
            return el.buy==true;
        });
        if (bp) {
            calc.neededBP = bp.r;
        }
        calc.neededRuns = neededRuns;
        calc.buyMats = 0;
        calc.m = [];
        host.m.forEach((m, idx) => {
            let needed = meArr.reduce((prev, mentry) => {
                prev += calculateMEmats(m.q, settings, mentry.r, mentry.ME);
                return prev;
            }, 0);
            let si = {
                needed: needed,
                path: [...stepInfo.path, idx]
            }
            calc.m[idx] = {};
            calculate.call(this, m, calc.m[idx], si, available);
            calc.buyMats += needed * propByPath(this.p, [m.i.id, 'sell', this.conf.region, 'min']);
        })
        calc.jobCost = manufacturingJobCost(host.m, this.ci, settings) * calc.neededRuns;
    }
    calc.path = stepInfo.path;
    return calc;
}

/**
 * returns an array of blueprints with their possible runs, ME, TE and chrId.
 * Possibly reduces the available amount of blueprints.
 */
const getMEArray = function(available, id, neededRuns, isReaction) {
    let bps = available ? available[id] : false;
    let meArr = [];
    let rc = neededRuns;
    if (bps) {
        bps = bps.sort((a, b) => {
            if (a.ME < b.ME) return 1;
            if (a.ME > b.ME) return -1;
            if (a.r != -1 && b.r == -1) return 1;
            if (a.r == -1 && b.r != -1) return -1;
            if (a.r < b.r) return 1;
            if (a.r > b.r) return -1;
            return 0;
        });
        while (rc > 0 && bps.length > 0) {
            if (bps[0].r == -1) {
                // unlimited runs
                meArr.push({
                    ME: bps[0].ME,
                    TE: bps[0].TE,
                    chr: bps[0].chr,
                    r: rc
                });
                rc = 0;
                break;
            }
            if (bps[0].r > rc) {
                // reduce run-count and finish
                bps[0].r -= rc;
                meArr.push({
                    ME: bps[0].ME,
                    TE: bps[0].TE,
                    chr: bps[0].chr,
                    r: rc
                });
                rc = 0;
                break;
            }
            let curr = bps.shift();
            meArr.push({
                ME: curr.ME,
                TE: curr.TE,
                chr: curr.chr,
                r: curr.r
            });
            rc -= curr.r;
        }
    }
    if (rc > 0) {
        if (isReaction) {
            meArr.push({
                me: 0,
                buy: true,
                r: rc
            });
        } else {
            meArr.push({
                me: settings.engineering.ME,
                buy: true,
                r: rc
            });
        }
    }
    if (available && available.b) {
        available.b[id] = bps;
    }
    return meArr;
};

const addUnique = function(arr, val) {
    if (arr.indexOf(val) > -1) {
        return;
    }
    arr.push(val);
};

const getRecursiveAssetsAndBlueprints = function(host, data) {
    if (host.bp) {
        addUnique(data.bp, host.bp.i.id);
        if (host.bp.f) {
            addUnique(data.bp, host.bp.f.id);
            host.bp.fm.forEach((m) => {
                addUnique(data.a, m.i.id);
            });
        }
    }
    if (host.m) {
        host.m.forEach((m) => {
            addUnique(data.a, m.i.id);
            data = getRecursiveAssetsAndBlueprints(m, data);
        });
    }
    return data;
};

export default productionCost

import axios from '../axios.js'

const assets = {
    namespaced: true,
    state: () => ({
        "containers": [],
        "currentContent": [],
        "contentCache": {},
        "currentContainer": {},
        "loading": false
    }),
    mutations: {
        SAVE_CONTAINERS(state, containers) {
            state.containers = containers;
        },
        SAVE_CONTENTS(state, data) {
            state.currentContent = data.contents;
            state.currentContainer = state.containers.filter((cont) => cont.casId == data.container)[0];
        },
        CACHE_CONTENTS(state, {contents, id}) {
            state.contentCache[id] = contents;
        },
        ADD_SHARED_OBJECT(state, shared) {
            state.containers = state.containers.map((cont) => {
                if (cont.casId == shared.coni) {
                    cont.shared = shared;
                }
                return cont;
            })
        },
        REMOVE_SHARED_OBJECT(state, id) {
            state.containers = state.containers.map((cont) => {
                if (cont.casId == id) {
                    delete cont.shared;
                }
                return cont;
            })
        },
        SET_LOADING(state, val) {
            state.loading = val;
        }
    },
    actions: {
        loadContainers({commit, state, dispatch}) {
            if (state.loading) {
                return;
            }
            return new Promise(
                (resolve, reject) => {
                    commit("SET_LOADING", true);
                    dispatch('globalState/setLoading', { which: "Assets", bol: true}, {root: true});
                    axios.get('user/containers').then(result => {
                        commit('SAVE_CONTAINERS', result.data);
                        dispatch('globalState/setLoading', { which: "Assets", bol: false}, {root: true});
                        commit("SET_LOADING", false);
                        resolve();
                    }).catch(() => {
                        dispatch('globalState/setLoading', { which: "Assets", bol: false }, { root: true });
                        commit("SET_LOADING", false);
                    });
                });
        },
        loadContents({commit, state, dispatch}, id) {
            dispatch('globalState/setLoading', { which: "Assets", bol: true }, { root: true });
            return new Promise(
                (resolve, reject) => {
                    if (state.contentCache[id]) {
                        commit("SAVE_CONTENTS", state.contentCache[id]);
                        dispatch('globalState/setLoading', { which: "Assets", bol: false }, { root: true });
                        resolve();
                    } else {
                        axios.get('user/containers/' + id).then(result => {
                            commit("CACHE_CONTENTS", {contents: result.data, id: id});
                            commit("SAVE_CONTENTS", {contents: result.data, container: id});
                            dispatch('globalState/setLoading', { which: "Assets", bol: false }, { root: true });
                            resolve();
                        }).catch(() => {
                            dispatch('globalState/setLoading', { which: "Assets", bol: false }, { root: true });
                            commit("SET_LOADING", false);
                        });
                    }
                }
            )
        },
        shareContainer({commit, state}, dat) {
            let id = dat.cid;
            let qry = Object.getOwnPropertyNames(dat.formulas).reduce((prev, prop) => {
              prev.push(prop + "=" + dat.formulas[prop]);
              return prev;
            }, []);
            return new Promise(
                (resolve, reject) => {
                    axios.get('user/shareContainer/' + id + "?" + qry.join('&')).then(result => {
                        commit("ADD_SHARED_OBJECT", result.data);
                        resolve();
                    });
                }
            )
        },
        unshareContainer({commit, state}, id) {
            return new Promise(
                (resolve, reject) => {
                    axios.get('user/unshareContainer/' + id).then(result => {
                        commit("REMOVE_SHARED_OBJECT", id);
                        resolve();
                    });
                }
            )
        },
        characterSwitched({commit, dispatch}) {
            commit("SAVE_CONTENTS", {contents: []});
            commit("SAVE_CONTAINERS", {containers: []});
            dispatch("loadContainers");
        },
    }
}

export default assets

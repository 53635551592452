import axios from '../axios.js'

var notifyNew = new Audio('/sound/notification.mp3');
var notifyMark = new Audio('/sound/pricemark.mp3');
var pnList = localStorage.getItem('pnList');
if (pnList) {
    pnList = JSON.parse(pnList);
} else {
    pnList = {};
}

const checkPriceMark = function(itemId, orders) {
    let marks = pnList[itemId];
    if (!marks) return;
    let alert = false;
    orders.forEach((o) => {
        if (alert) return;
        if (o.is_buy_order) {
            if (!marks.buy) return;
            alert = marks.buy <= o.price;
        } else {
            if (!marks.sell) return;
            alert = marks.sell >= o.price;
        }
    });
    if (alert)
        notifyMark.play();
};

const order = {
    namespaced: true,
    state: () => ({
        item: {},
        list: [],
        routes: {},
        marketStats: {
            highSec: {},
            allSec: {}
        },
        loading: false,
        freshnes: 0,
        viewOpen: false,
        playSound: false,
        timeout: false,
        priceNotification: {},
        chrLocation: false
    }),
    mutations: {
        SAVE_ORDERS(state, { item, orders }) {
            state.item = item;
            state.list = orders.orders;
            state.marketStats.highSec = orders.msHi;
            state.marketStats.allSec = orders.ms;
            state.priceNotification = pnList[item.id];
            state.routes = orders.routes;
            state.chrLocation = orders.cl;
        },
        ADD_ORDERS(state, orders) {
            state.list = state.list.filter((o) => {
                if (o.added) o.added = false;
                return orders.deleted.indexOf(o.id) == -1;
            });
            Object.getOwnPropertyNames(orders.routes).forEach((key) => {
                if (state.routes[key]) return;
                console.log("add new route to route-store", key);
                state.routes[key] = orders.routes[key];
            });
            orders.orders.forEach((el) => {
                if (el.volume_remain <= 0) {
                    return;
                }
                let idx = state.list.findIndex((le) => {
                    return le.id == el.id;
                });
                if (idx > -1) {
                    state.list.splice(idx,1);
                }
                el.added = true;
                state.list.push(el);
            });
            state.marketStats.highSec = orders.msHi;
            state.marketStats.allSec = orders.ms;
            if (state.chrLocation?.id != orders.cl?.id) {
                state.chrLocation = orders.cl;
            }
        },
        SET_VIEW_OPEN(state, val) {
            state.viewOpen = val;
        },
        SET_FRESHNES(state, freshnes) {
            state.freshnes = freshnes;
        },
        SET_PLAY_SOUND(state, val) {
            state.playSound = val;
        },
        SET_TIMEOUT(state, val) {
            state.timeout = val;
        },
        SET_PRICE_NOTIFICATION(state, {itemId, marks}) {
            state.priceNotification = marks;
            pnList[itemId] = marks;
            localStorage.setItem('pnList', JSON.stringify(pnList));
        },
        UPDATE_ROUTES(state, routes) {
            Object.getOwnPropertyNames(routes).forEach((ssid) => {
                if (!state.routes[ssid]) {
                    state.routes[ssid] = routes[ssid];
                    console.log("added new route", ssid);
                    return;
                }
                let curr = state.routes[ssid];
                let r = routes[ssid];
                ['direct', 'low', 'high'].forEach((prop) => {
                    curr[prop] = r[prop];
                });
                console.log("updated route", ssid);
            });
        }
    },
    actions: {
        toggleUpdate ({ commit, state, dispatch }) {
            if (!state.timeout) {
                console.log("start timeout");
                dispatch("checkFreshnes");
            } else {
                console.log("clear timeout");
                clearTimeout(state.timeout);
                commit("SET_TIMEOUT", false);
            }
        },
        toggleSound ({ commit, state }) {
            commit("SET_PLAY_SOUND", !state.playSound);
        },
        reload ({state, dispatch}) {
            if (!state.item) return;
            dispatch('load', state.item);
        },
        updateRoutes ({commit, state}) {
            if (!state.list) return;
            let ssids = state.list.reduce((prev, curr) => {
                if (!curr?.location?.ssid) return prev;
                prev[curr.location.ssid] = true;
                return prev;
            }, {});
            axios.post('routes/system/bulk', {
                systems: JSON.stringify(Object.getOwnPropertyNames(ssids))
            }).then((result) => {
                commit('UPDATE_ROUTES', result.data);
            })
        },
        load ({commit, dispatch, state}, item) {
            return new Promise((resolve, reject) => {
                if (!item?.id) {
                    resolve();
                    return;
                }
                dispatch('globalState/setLoading', {which: "Orders", bol: true}, {root: true});
                axios.get('market/orders/' + item.id).then(result => {
                    commit('SAVE_ORDERS', { item: item, orders: result.data});
                    commit('SET_FRESHNES', Date.now());
                    checkPriceMark(item.id, result.data.orders);
                    if (state.timeout !== false) {
                        clearTimeout(state.timeout);
                        commit("SET_TIMEOUT", setTimeout(() => {
                            dispatch("checkFreshnes");
                        }, 30000));
                    }
                    if (result.error) {
                        dispatch("globalState/addMessage", {
                            type: "error",
                            message: result.error
                        })
                    }
                    resolve();
                }).catch((error) => {
                    if (error.response) {
                        dispatch("globalState/addMessage", {
                            type: "error",
                            message: "Server responded with status-code " + error.response.status,
                            details: error
                        }, { root: true });
                    } else if (error.request) {
                        dispatch("globalState/addMessage", {
                            type: "error",
                            message: "No response from Server",
                            details: error
                        }, { root: true });
                    } else {
                        dispatch("globalState/addMessage", {
                            type: "error",
                            message: "Failed axios request",
                            details: error
                        }, { root: true });
                    }
                    reject();
                }).then(() => {
                    dispatch('globalState/setLoading', {which: "Orders", bol: false}, {root: true});
                });
            });
        },
        setViewOpen ({commit}, val) {
            commit("SET_VIEW_OPEN", val);
        },
        checkFreshnes({commit, dispatch, state}) {
            if (state.viewOpen) {
                axios.get("market/orders/" + state.item.id + "/" + state.freshnes).then((result) => {
                    if (!result.data || !result.data.orders || (result.data.orders.length < 1 && result.data.deleted.length < 1)) return;
                    commit("ADD_ORDERS", result.data);
                    commit("SET_FRESHNES", Date.now());
                    if (result.data.orders.length>0) {
                        checkPriceMark(state.item.id, result.data.orders);
                    }
                    if (state.playSound && result.data.orders.length > 0) {
                        notifyNew.play();
                    }
                }).catch((e) => {
                    console.log("check for fresh orders error:", e);
                });
            }
            commit("SET_TIMEOUT", setTimeout(() => {
                dispatch("checkFreshnes");
            }, 30000));
        },
        /**
         * @param {*} obj Object with properties itemId (the item id) and marks.buy/marks.sell
         */
        setPriceNotification({commit}, obj) {
            commit('SET_PRICE_NOTIFICATION', obj);
        }
    }
}

export default order

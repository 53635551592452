export const itemEquals = function(a, b) {
    if (!a && b) return false;
    if (a && !b) return false;
    if (!a && !b) return true;
    return a.id == b.id;
}

export const formatNumber = function(val) {
    if (isNaN(val)) {
        return '-';
    }
    return Number(val).toLocaleString(undefined, {useGrouping: true, maximumFractionDigits: 2});
}

export const guid =  () => {
    let s4 = () => {
        return Math.floor((1 + Math.random()) * 0x10000)
            .toString(16)
            .substring(1);
    }
    return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
}

export const formatPrice = function(val) {
    if (isNaN(val)) {
        return '<span class="NaN">-</span>';
    }
    let price = Number(val).toLocaleString(undefined, {useGrouping: true, minimumFractionDigits: 2, maximumFractionDigits: 2});
    let arr = price.split(".");
    return arr.map((el, idx) => {
        return '<span class="ps' + (arr.length - idx) + '">' + el + '</span>';
    }).join('.') + '<span class="currency">ISK</span>';
    // return Number(val).toLocaleString(undefined, {useGrouping: true, minimumFractionDigits: 2, maximumFractionDigits: 2}) + '<span class="currency">ISK</span>';
}

export const getIconUrlForItem = function(itm, size) {
    if (itm.categoryName == "Blueprint") {
        return 'https://images.evetech.net/types/' + itm.id + '/bp?size=' + size;
    } else if (itm.categoryName == "SKINs") {
        return '/img/skin.png';
    } else if (itm.categoryName == "Ancient Relics") {
        return 'https://images.evetech.net/types/' + itm.id + '/relic?size=' + size;
    }
    return 'https://images.evetech.net/types/' + itm.id + '/icon?size=' + size;
}

export const propByPath = function(host, path) {
    let val = host;
    let key;
    while (key = path.shift()) {
        val = val[key];
        if (!val) {
            return NaN;
        }
    }
    return val;
}

export const objectAsArray = function(obj) {
    return Object.getOwnPropertyNames(obj).reduce((prev, key) => {
        if (key.indexOf("__") == 0) {
            return prev;
        }
        prev.push({
            key: key,
            data: obj[key]
        });
        return prev;
    }, []);
};

/**
 * Pad the given val with leading zeros if less then 'len' characters long. Cut it to fit 'len' characters if too long.
 * Return an empty string if no val is given.
 * @param {integer} val The value to pad
 * @param {integer} len Length of the resulting string
 * @returns Empty string if no value has been given or a string having 'len' characters.
 */
export const pad = function (val, len) {
    if (val == undefined || val == null) return "";
    var strg = "" + val;
    var n = len || 2;
    while (strg.length < n) {
        strg = "0" + strg;
    }
    return strg;
}

/**
 * Place the caret at the end of the DOMElement.
 * @param {DOMElement} el
 */
export const placeCaretAtEnd = function (el) {
    el.focus();
    setTimeout(() => {
        if (typeof window.getSelection != "undefined"
            && typeof document.createRange != "undefined") {
            var range = document.createRange();
            range.selectNodeContents(el);
            range.collapse(false);
            var sel = window.getSelection();
            sel.removeAllRanges();
            sel.addRange(range);
        } else if (typeof document.body.createTextRange != "undefined") {
            var textRange = document.body.createTextRange();
            textRange.moveToElementText(el);
            textRange.collapse(false);
            textRange.select();
        }
    }, 1);
};

/**
 * Traverse up in the DOMElement-tree to find the direct child of the parent-node having the given id.
 * @param {DOMElement} el The DOMElement to find the direct child of the Parent-node having given id
 * @param {String} domid The DOMElement.id of the parent of which we are searching the direct child
 * @returns The direct child of the parent-node having the given domid
 */
export const findChildOfParentNode = function(el, domid) {
    if (el.id == domid) return;
    while (el.parentNode && el.parentNode.id != domid) {
        el = el.parentNode;
    }
    return el;
};

/**
 * If given DOMElement is of type text, this will return a div wrapping this text.
 * @param {DOMElement} el The DOMElement to wrap into a div-tag if it's a text-node
 * @returns Given DOMElement or if it has been of type #text, this text wrapped into a div-tag
 */
export const wrapTextInDiv = function (el) {
    if (!el || !el.nodeName) {
        return el;
    }
    if (el.nodeName=='#text') {
        let dEl = document.createElement('div');
        el.after(dEl);
        dEl.append(el);
        placeCaretAtEnd(dEl);
        return dEl;
    }
    return el;
};

/**
 * Returns the class-name for the given system's security-status
 * @param {Object} sys The system-object holding the security-status
 * @returns The class-name corresponding to the systems security-status
 */
export const getSecClass = function(sys) {
    if (!sys || (!sys.sec && !sys.securityStatus)) return;
    return "sec" + pad(Math.max((Math.round((sys.sec || sys.securityStatus) * 10)), 0), 2);
};

export const stripHTML = function(val) {
    return val.replace(/(<([^>]+)>)/ig, "");
};
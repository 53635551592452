<template>
  <div id="messages" v-if="messages && messages.length > 0">
      <div v-for="msg of messages" :key="msg.id" v-bind:class="msg.type" :class="{error: msg.details && msg.details.error}">
          <SvgAtom :name="'attention.svg'" />
          <span><div>{{ msg.message }}</div>
          <div class="messageDetails" v-if="msg.details && msg.details.isAxiosError">{{ msg.details?.response?.data?.error?.message || msg.details.error.message }}</div>
          <div class="messageDetails" v-if="msg.details && !msg.details.isAxiosError">{{ msg.details }}</div></span>
      </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import SvgAtom from './atoms/SvgAtom.vue'

export default {
  components: {
      SvgAtom
  },
  name: 'Messages',
  computed: {
      messages() {
          return this.$store.state.globalState.messages;
      }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
@import "@/scss/_variables.scss";

@media only screen and (max-width: 600px) {
    #messages {
        top:131px;
        left: 0;
        width: 80vw;
    }
}
@media only screen and (min-width: 600px) {
    #messages {
        top: 131px;
        left: 0;
        width: 80vw;
    }
}
@media only screen and (min-width: 800px) {
    #messages {
        top:131px;
        left: 25vw;
        width: 50vw;
    }
}

#messages {
    padding: 10px 3px;
    position: fixed;
    z-index: 999;
    background-color: #111;
    display: inline-block;
    border: 3px solid $c-background;
    border-top-width: 0;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    text-align: left;
    display: grid;
    grid-template-columns: 32px 1fr;
    gap: 4px;
    align-items: center;
    >div {
        display: contents;
        border-bottom: 2px solid #3b3822;
    }

    .bg {
        fill: $c-font;
    }
    .ol {
        fill: $c-border;
    }
    .error {
        .em {
            fill: #a00;
        }
        .ol {
            fill: #a00;
        }
    }
}
</style>

<template>
<div class="socket">
  <div class="gel center-gel"><div class="hex-brick h1"></div><div class="hex-brick h2"></div><div class="hex-brick h3"></div></div>
  <div class="gel c1 r1"><div class="hex-brick h1"></div><div class="hex-brick h2"></div><div class="hex-brick h3"></div></div>
  <div class="gel c2 r1"><div class="hex-brick h1"></div><div class="hex-brick h2"></div><div class="hex-brick h3"></div></div>
  <div class="gel c3 r1"><div class="hex-brick h1"></div><div class="hex-brick h2"></div><div class="hex-brick h3"></div></div>
  <div class="gel c4 r1"><div class="hex-brick h1"></div><div class="hex-brick h2"></div><div class="hex-brick h3"></div></div>
  <div class="gel c5 r1"><div class="hex-brick h1"></div><div class="hex-brick h2"></div><div class="hex-brick h3"></div></div>
  <div class="gel c6 r1"><div class="hex-brick h1"></div><div class="hex-brick h2"></div><div class="hex-brick h3"></div></div>
  <div class="gel c7 r2"><div class="hex-brick h1"></div><div class="hex-brick h2"></div><div class="hex-brick h3"></div></div>
  <div class="gel c8 r2"><div class="hex-brick h1"></div><div class="hex-brick h2"></div><div class="hex-brick h3"></div></div>
  <div class="gel c9 r2"><div class="hex-brick h1"></div><div class="hex-brick h2"></div><div class="hex-brick h3"></div></div>
  <div class="gel c10 r2"><div class="hex-brick h1"></div><div class="hex-brick h2"></div><div class="hex-brick h3"></div></div>
  <div class="gel c11 r2"><div class="hex-brick h1"></div><div class="hex-brick h2"></div><div class="hex-brick h3"></div></div>
  <div class="gel c12 r2"><div class="hex-brick h1"></div><div class="hex-brick h2"></div><div class="hex-brick h3"></div></div>
  <div class="gel c13 r2"><div class="hex-brick h1"></div><div class="hex-brick h2"></div><div class="hex-brick h3"></div></div>
  <div class="gel c14 r2"><div class="hex-brick h1"></div><div class="hex-brick h2"></div><div class="hex-brick h3"></div></div>
  <div class="gel c15 r2"><div class="hex-brick h1"></div><div class="hex-brick h2"></div><div class="hex-brick h3"></div></div>
  <div class="gel c16 r2"><div class="hex-brick h1"></div><div class="hex-brick h2"></div><div class="hex-brick h3"></div></div>
  <div class="gel c17 r2"><div class="hex-brick h1"></div><div class="hex-brick h2"></div><div class="hex-brick h3"></div></div>
  <div class="gel c18 r2"><div class="hex-brick h1"></div><div class="hex-brick h2"></div><div class="hex-brick h3"></div></div>
  <div class="gel c19 r3"><div class="hex-brick h1"></div><div class="hex-brick h2"></div><div class="hex-brick h3"></div></div>
  <div class="gel c20 r3"><div class="hex-brick h1"></div><div class="hex-brick h2"></div><div class="hex-brick h3"></div></div>
  <div class="gel c21 r3"><div class="hex-brick h1"></div><div class="hex-brick h2"></div><div class="hex-brick h3"></div></div>
  <div class="gel c22 r3"><div class="hex-brick h1"></div><div class="hex-brick h2"></div><div class="hex-brick h3"></div></div>
  <div class="gel c23 r3"><div class="hex-brick h1"></div><div class="hex-brick h2"></div><div class="hex-brick h3"></div></div>
  <div class="gel c24 r3"><div class="hex-brick h1"></div><div class="hex-brick h2"></div><div class="hex-brick h3"></div></div>
  <div class="gel c25 r3"><div class="hex-brick h1"></div><div class="hex-brick h2"></div><div class="hex-brick h3"></div></div>
  <div class="gel c26 r3"><div class="hex-brick h1"></div><div class="hex-brick h2"></div><div class="hex-brick h3"></div></div>
  <div class="gel c28 r3"><div class="hex-brick h1"></div><div class="hex-brick h2"></div><div class="hex-brick h3"></div></div>
  <div class="gel c29 r3"><div class="hex-brick h1"></div><div class="hex-brick h2"></div><div class="hex-brick h3"></div></div>
  <div class="gel c30 r3"><div class="hex-brick h1"></div><div class="hex-brick h2"></div><div class="hex-brick h3"></div></div>
  <div class="gel c31 r3"><div class="hex-brick h1"></div><div class="hex-brick h2"></div><div class="hex-brick h3"></div></div>
  <div class="gel c32 r3"><div class="hex-brick h1"></div><div class="hex-brick h2"></div><div class="hex-brick h3"></div></div>
  <div class="gel c33 r3"><div class="hex-brick h1"></div><div class="hex-brick h2"></div><div class="hex-brick h3"></div></div>
  <div class="gel c34 r3"><div class="hex-brick h1"></div><div class="hex-brick h2"></div><div class="hex-brick h3"></div></div>
  <div class="gel c35 r3"><div class="hex-brick h1"></div><div class="hex-brick h2"></div><div class="hex-brick h3"></div></div>
  <div class="gel c36 r3"><div class="hex-brick h1"></div><div class="hex-brick h2"></div><div class="hex-brick h3"></div></div>
  <div class="gel c37 r3"><div class="hex-brick h1"></div><div class="hex-brick h2"></div><div class="hex-brick h3"></div></div>
</div>
</template>

<script>
export default {
  name: 'TheLoadingSpinner'
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">

      .socket{
        z-index: 100;
        width: 90%;
        height: 90%;
        position: absolute;
        left: 55%;
        margin-left: -50%;
        top: 55%;
        margin-top: -50%;
      }

      .hex-brick{
        background: #ABF8FF;
        width: 90%;
        height: 50%;
        position: absolute;
        top: 2.5%;
        animation-name: fade;
        animation-duration: 2s;
        animation-iteration-count: infinite;
        -webkit-animation-name: fade;
        -webkit-animation-duration: 2s;
        -webkit-animation-iteration-count: infinite;
      }

      .h2{
        transform: rotate(60deg);
        -webkit-transform: rotate(60deg);
      }

      .h3{
        transform: rotate(-60deg);
        -webkit-transform: rotate(-60deg);
      }

      .gel{
        height: 15%;
        width: 15%;
        transition: all .3s;
        -webkit-transition: all .3s;
        position: absolute;
        top: 50%;
        left: 50%;
      }

      .center-gel{
        margin-left: -7.5%;
        margin-top: -7.5%;

        animation-name: pulse;
        animation-duration: 2s;
        animation-iteration-count: infinite;
        -webkit-animation-name: pulse;
        -webkit-animation-duration: 2s;
        -webkit-animation-iteration-count: infinite;
      }

      .c1{
        margin-left: -23.5%;
        margin-top: -7.5%;
      }

      .c2{
        margin-left: -15.5%;
        margin-top: -21.5%;
      }

      .c3{
        margin-left: .5%;
        margin-top: -21.5%;
      }

      .c4{
        margin-left: 8.5%;
        margin-top: -7.5%;
      }
      .c5{
        margin-left: -15.5%;
        margin-top: 6.5%;
      }

      .c6{
        margin-left: .5%;
        margin-top: 6.5%;
      }

      .c7{
        margin-left: -31.5%;
        margin-top: -21.5%;
      }

      .c8{
        margin-left: 16.5%;
        margin-top: -21.5%;
      }

      .c9{
        margin-left: -7.5%;
        margin-top: 20.5%;
      }

      .c10{
        margin-left: -31.5%;
        margin-top: 6.5%;
      }

      .c11{
        margin-left: 16.5%;
        margin-top: 6.5%;
      }

      .c12{
        margin-left: -7.5%;
        margin-top: -35.5%;
      }

      .c13{
        margin-left: -23.5%;
        margin-top: -35.5%;
      }

      .c14{
        margin-left: 8.5%;
        margin-top: -35.5%;
      }

      .c15{
        margin-left: -23.5%;
        margin-top: 20.5%;
      }

      .c16{
        margin-left: 8.5%;
        margin-top: 20.5%;
      }

      .c17{
        margin-left: -39.5%;
        margin-top: -7.5%;
      }

      .c18{
        margin-left: 24.5%;
        margin-top: -7.5%;
      }

      .c19{
        margin-left: -31.5%;
        margin-top: -49.5%;
      }

      .c20{
        margin-left: 15.5%;
        margin-top: -49.5%;
      }

      .c21{
        margin-left: .5%;
        margin-top: -49.5%;
      }

      .c22{
        margin-left: -15.5%;
        margin-top: -49.5%;
      }

      .c23{
        margin-left: -31.5%;
        margin-top: 34.5%;
      }

      .c24{
        margin-left: 16.5%;
        margin-top: 34.5%;
      }

      .c25{
        margin-left: .5%;
        margin-top: 34.5%;
      }

      .c26{
        margin-left: -15.5%;
        margin-top: 34.5%;
      }

      .c27{
        margin-left: -39.5%;
        margin-top: -7.5%;
      }

      .c28{
        margin-left: -47.5%;
        margin-top: -21.5%;
      }

      .c29{
        margin-left: -47.5%;
        margin-top: 6.5%;
      }

      .c30{
        margin-left: 24.5%;
        margin-top: 20.5%;
      }

      .c31{
        margin-left: -39.5%;
        margin-top: -35.5%;
      }

      .c32{
        margin-left: -55.5%;
        margin-top: -7.5%;
      }

      .c33{
        margin-left: 32.5%;
        margin-top: -21.5%;
      }

      .c34{
        margin-left: 32.5%;
        margin-top: 6.5%;
      }

      .c35{
        margin-left: -39.5%;
        margin-top: 20.5%;
      }

      .c36{
        margin-left: 24.5%;
        margin-top: -35.5%;
      }

      .c37{
        margin-left: 40.5%;
        margin-top: -7.5%;
      }

      .r1{
        animation-name: pulse;
        animation-duration: 2s;
        animation-iteration-count: infinite;
        animation-delay: .2s;
        -webkit-animation-name: pulse;
        -webkit-animation-duration: 2s;
        -webkit-animation-iteration-count: infinite;
        -webkit-animation-delay: .2s;
      }

      .r2{
        animation-name: pulse;
        animation-duration: 2s;
        animation-iteration-count: infinite;
        animation-delay: .4s;
        -webkit-animation-name: pulse;
        -webkit-animation-duration: 2s;
        -webkit-animation-iteration-count: infinite;
        -webkit-animation-delay: .4s;
      }

      .r3{
        animation-name: pulse;
        animation-duration: 2s;
        animation-iteration-count: infinite;
        animation-delay: .6s;
        -webkit-animation-name: pulse;
        -webkit-animation-duration: 2s;
        -webkit-animation-iteration-count: infinite;
        -webkit-animation-delay: .6s;
      }

      .r1 > .hex-brick{
        animation-name: fade;
        animation-duration: 2s;
        animation-iteration-count: infinite;
        animation-delay: .2s;
        -webkit-animation-name: fade;
        -webkit-animation-duration: 2s;
        -webkit-animation-iteration-count: infinite;
        -webkit-animation-delay: .2s;
      }

      .r2 > .hex-brick{
        animation-name: fade;
        animation-duration: 2s;
        animation-iteration-count: infinite;
        animation-delay: .4s;
        -webkit-animation-name: fade;
        -webkit-animation-duration: 2s;
        -webkit-animation-iteration-count: infinite;
        -webkit-animation-delay: .4s;
      }

      .r3 > .hex-brick{
        animation-name: fade;
        animation-duration: 2s;
        animation-iteration-count: infinite;
        animation-delay: .6s;
        -webkit-animation-name: fade;
        -webkit-animation-duration: 2s;
        -webkit-animation-iteration-count: infinite;
        -webkit-animation-delay: .6s;
      }


      @keyframes pulse{
        0%{
          -webkit-transform: scale(1);
          transform: scale(1);
        }

        50%{
          -webkit-transform: scale(0.01);
          transform: scale(0.01);
        }

        100%{
          -webkit-transform: scale(1);
          transform: scale(1);
        }
      }

      @keyframes fade{
        0%{
          background: #ABF8FF;
        }

        50%{
          background: #90BBBF;
        }

        100%{
          background: #ABF8FF;
        }
      }

      @-webkit-keyframes pulse{
        0%{
          -webkit-transform: scale(1);
          transform: scale(1);
        }

        50%{
          -webkit-transform: scale(0.01);
          transform: scale(0.01);
        }

        100%{
          -webkit-transform: scale(1);
          transform: scale(1);
        }
      }

      @-webkit-keyframes fade{
        0%{
          background: #ABF8FF;
        }

        50%{
          background: #389CA6;
        }

        100%{
          background: #ABF8FF;
        }
      }

</style>

import axios from '../axios.js'
import EventBus from "../main.js";

const characterRequest = function(commit, dispatch, action, params) {
    commit("globalState/loginLoading", true, { root: true });
    let axParams = params ? { params: params } : undefined;
    axios.get(action, axParams).then(rslt => {
        let result = rslt.data;
        commit('SAVE_USERS', result.all);
        commit('SAVE_ACTIVE_USER', result.main);
        commit("globalState/loginLoading", false, { root: true });
    }).catch(() => {
        commit("globalState/loginLoading", false, { root: true });
    });
};

const characters = {
    namespaced: true,
    state: () => ({
        "all": [],
        "main": undefined,
        "notify": [],
        "lastNotify": 0
    }),
    mutations: {
        SAVE_USERS(state, users) {
            state.all = users;
        },
        SAVE_ACTIVE_USER(state, main) {
            state.main = main;
        },
        ADD_LISTENER(state, listener) {
            state.notify.push(listener);
        },
        REMOVE_LISTENER(state, listener) {
            state.notify = state.notify.filter((el) => {
                return el != listener;
            });
        },
        UPDATE_LAST_NOTIFIED(state) {
            state.lastNotify = Date.now();
        }
    },
    actions: {
        loadUsers({ commit, dispatch }) {
            characterRequest(commit, dispatch, 'user');
        },
        removeCharacter({ commit, dispatch }, characterId) {
            characterRequest(commit, dispatch, 'user/removeCharacter', { characterId: characterId});
        },
        promoteCharacter({ commit, dispatch }, characterId) {
            characterRequest(commit, dispatch, 'user/promoteCharacter', { characterId: characterId});
        },
        addListener({ commit }, action) {
            commit("ADD_LISTENER", action);
        },
        removeListener({ commit }, action) {
            commit("REMOVE_LISTENER", action);
        },
        notifyListeners({ dispatch, commit, state }) {
            if (state.lastNotify!=0 && (Date.now()-state.lastNotify) < 2000) {
                return;
            }
            state.notify.forEach((action) => {
                dispatch(action, undefined, {root: true});
            });
            commit("UPDATE_LAST_NOTIFIED");
        }
    }
}

export default characters

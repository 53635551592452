import axios from '../axios.js'

const settings = {
    namespaced: true,
    state: () => ({
        list: {
            loading: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 330 330" xml:space="preserve"><path d="M97.5 165a15 15 0 0 0-15-15h-60a15 15 0 0 0 0 30h60a15 15 0 0 0 15-15zm210-15h-30a15 15 0 0 0 0 30h30a15 15 0 0 0 0-30zm-135-60a15 15 0 0 0 15-15V15a15 15 0 0 0-30 0v60a15 15 0 0 0 15 15zm0 150a15 15 0 0 0-15 15v60a15 15 0 0 0 30 0v-60a15 15 0 0 0-15-15zM77 48.3a15 15 0 0 0-21.2 21.2L98.3 112a15 15 0 0 0 21.2 0 15 15 0 0 0 0-21.2L77 48.3zM246.7 218a15 15 0 0 0-21.2 21.2l42.5 42.5a15 15 0 0 0 21.2 0 15 15 0 0 0 0-21.2L246.7 218zm-148.4 0-42.5 42.5A15 15 0 1 0 77 281.7l42.5-42.5A15 15 0 1 0 98.3 218z"/></svg>'
        }
    }),
    mutations: {
        ADD_SVG(state, {name, svg}) {
            state.list[name] = svg;
        }
    },
    actions: {
        load({state, commit}, name) {
            if (state.list[name] && state.list[name].length > 0) {
                return;
            }
            commit('ADD_SVG', {name: name, svg: state.list.loading });
            axios.request({
                url: name,
                baseURL: '/img/'
            }).then((result) => {
                commit("ADD_SVG", {name: name, svg: result.data});
            })
        }
    }
}

export default settings

import Structures from '../staticData/structures.js'
import Skills from '../staticData/skills.js'
import Vue from "vue"

var state;
try {
    state = JSON.parse(localStorage.getItem('settings'));
} catch(e) { }

if (!state) {
    state = {
        'engineering': {
            'station': Structures.getByName('Raitaru'),
            'tax': 5,
            'ME': 10,
            'TE': 20
        },
        'refining': {
            'station': Structures.getByName('Athanor'),
            'tax': 5,
            'rig': Structures.getByName("T1 Rig"),
            'systemSecurity': 'HighSec'
        },
        'skills': {},
        'market': {
            'salesTax': 3.6,
            'brokerFee': 1.49
        }
    };
    Skills.skills.forEach((s) => {
        state.skills[s.name] = 5;
    });
}

if (!state.implants) {
    state.implants = {'beancounter': 0};
}


const settings = {
    namespaced: true,
    state: () => (state),
    mutations: {
        SAVE_ENG_STRUCT(state, obj) {
            state.engineering.station = obj;
        },
        SAVE_REF_STRUCT(state, obj) {
            state.refining.station = obj;
        },
        SAVE_REF_RIG(state, obj) {
            state.refining.rig = obj;
        }
    },
    actions: {
        save({state}) {
            localStorage.setItem("settings", JSON.stringify(state));
        }
    }
}

export default settings

const appraisal = {
    namespaced: true,
    state: () => ({
        result: [],
        matPrices: {},
        uuid: false
    }),
    mutations: {
        SET_RESULT(state, value) {
            state.result = value;
        },
        SET_MAT_PRICES(state, value) {
            state.matPrices = value;
        },
        SET_UUID(state, value) {
            state.uuid = value;
        }
    }
}

export default appraisal

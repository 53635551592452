module.exports.routes = [
    {
        path: "/",
        name: "",
        component: () => import(/* webpackChunkName: "home" */ '../views/Home.vue')
    },
    {
        path: "/index.html",
        name: "",
        component: () => import(/* webpackChunkName: "home" */ '../views/Home.vue')
    },
    {
        path: "/market/:itemId?/:showInfo?",
        name: "Market",
        alias: "/market/",
        component: () => import(/* webpackChunkName: "market" */ '../views/Market.vue'),
        props: true
    },
/*    {
        path: "/market/:itemId/:showInfo?",
        name: "Market",
        component: () => import(/* webpackChunkName: "market" * / '../views/Market.vue'),
        props: true
    },
    {
        path: "/prodCost/:itemId?",
        name: "Production",
        component: () => import(/* webpackChunkName: "production-planer" * / '../views/ProductionCost.vue')
    }, */
    {
        path: "/appraisal/:uuid?/:region?/:percent?",
        name: "Appraisal",
        alias: "/appraisal/",
        component: () => import(/* webpackChunkName: "appraisal" */ '../views/Appraisal.vue'),
        props: true
    },
    {
        path: "/bargain",
        name: "Schnäppchen",
        component: () => import(/* webpackChunkName: "schnaeppchen" */ '../views/Schnaeppchen.vue')
    },
    {
        path: "/settings",
        name: "Settings",
        component: () => import(/* webpackChunkName: "settings" */ '../views/Settings.vue')
    },
    {
        path: "/prodPlan/:itemId?",
        name: "ProductionPlaner",
        alias: "/prodPlan/",
        component: () => import(/* webpackChunkName: "production-planer" */ '../views/ProductionPlaner.vue'),
        props: true
    },
    {
        path: "/navigation/:uuid?",
        name: "Navigation",
        alias: "/navigation/",
        component: () => import(/* webpackChunkName: "navigation" */ '../views/Navigation.vue'),
        props: true
    },
    {
        path: "/assets",
        name: "Assets",
        component: () => import(/* webpackChunkName: "assets" */ '../components/market/TheAssets.vue')
    },
    {
        path: "/container/:containerId?",
        name: "Containerviewer",
        alias: "/container/",
        component: () => import(/* webpackChunkName: "containerViewer" */ '../views/ContainerViewer.vue'),
        props: true
    },
    {
        path: "/shop/:characterName?",
        name: "Shop",
        alias: "/shop/",
        component: () => import(/* webpackChunkName: "shop" */ '../views/Shop.vue'),
        props: true
    },
    {
        path: "/incursions",
        name: "incursions",
        component: () => import(/* webpackChunkName: "incursions" */ '../views/Incursion.vue')
    },
]

import axios from 'axios';

if (window.location.href.indexOf('localhost') > -1) {
    // axios.defaults.baseURL = "http://localhost:8080/api/current/";
    axios.defaults.baseURL = "https://eve.rything.org/api/current/";
  } else {
    axios.defaults.baseURL = "/api/current/";
  }
  axios.defaults.withCredentials = true;

  export default axios
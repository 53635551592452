<template>
    <span>
        <div id="characters" @mouseover="open = true" @mouseleave="open = false" @click="open = !open" :class="{ open: open }">
            <span class="spinnerContainer" v-if="loginLoading">
                <TheLoadingSpinner />
            </span>
            <Character :character="activeCharacter" v-if="activeCharacter" />
            <Character v-for="character of inactiveCharacters" :key="character.id" :character="character" />
            <span><button @click.stop="loginOpen = !loginOpen">Register a Char</button></span>
        </div>
        <div id="addCharacter" v-if="loginOpen">
            <ul>
                <li title="Enable all" @click.stop.prevent="checkAll" class="ca"><span>X</span><span>Check all</span></li>
                <li title="Used to calculate distance to orders, production-cost-index, ..."><input type="checkbox"
                        ref="esi-location.read_location.v1" @click.stop="" />Read location</li>
                <li title="Used to open the market-window for an item"><input type="checkbox" ref="esi-ui.open_window.v1"
                        checked="checked" @click.stop="" />Open window</li>
                <li title="Permission to set waypoints to navigate to the station of an order"><input type="checkbox"
                        ref="esi-ui.write_waypoint.v1" checked="checked" @click.stop="" />Write waypoints</li>
                <li title="Permission to read the characters blueprints"><input type="checkbox"
                        ref="esi-characters.read_blueprints.v1" @click.stop="" />Read blueprints</li>
                <li
                    title="Permission to read the characters assets. Needed to resolve containers which are target of blueprint-location-ids to resolve the station they are to be found">
                    <input type="checkbox" ref="esi-assets.read_assets.v1" @click.stop="" />Read assets</li>
                <li
                    title="Permission to read structure-information. This is used to locate Blueprints located on player-structures">
                    <input type="checkbox" ref="esi-universe.read_structures.v1" @click.stop="" />Read structures</li>
                <li title="Permission to send ingame-mail"><input type="checkbox" ref="esi-mail.send_mail.v1"
                        @click.stop="" />Send Mail</li>
            </ul>
            <a href="https://eve.rything.org/addCharacter" @click.stop.prevent="startProgressWatcher"
                target="loginWindow"><img src="../assets/eve-sso-login-black-small.png" /></a>
        </div>
    </span>
</template>

<script>
import Character from './Character.vue'
import { mapState } from 'vuex'
import TheLoadingSpinner from './TheLoadingSpinner.vue'
import { EventBus } from '../eventBus.js'

export default {
    name: 'Characters',
    components: {
        Character,
        TheLoadingSpinner
    },
    data() {
        return {
            open: false,
            loginOpen: false,
            eventRoute: false
        }
    },
    computed: {
        ...mapState(['characters']),
        activeCharacter: function () {
            var state = this.$store.state.characters;
            if (!state.all) {
                return false;
            }
            return {
                main: true,
                ...state.all.filter(function (chr) {
                    return chr.id === state.main;
                })[0]
            }
        },
        inactiveCharacters: function () {
            var state = this.$store.state.characters;
            if (!state.all) {
                return [];
            }
            return state.all.filter(function (chr) {
                return chr.id !== state.main;
            });
        },
        loginLoading: function () {
            return this.$store.state.globalState.loginLoading;
        },
        additionalScopes: function () {
            return document.location.search.indexOf("admin=true") > -1;
        }
    },
    created() {
        this.$store.dispatch('characters/loadUsers');
    },
    methods: {
        startProgressWatcher: function (e) {
            this.loginOpen = false;
            let refs = this.$refs;
            let arr = Object.keys(refs).reduce((prev, key) => {
                let el = refs[key];
                if (!el.checked) {
                    return prev;
                }
                prev.push(key);
                return prev;
            }, []);
            setTimeout(this.watchProgress, 5000);
            this.windowOpened = window.open(e.currentTarget.href + "?scopes=" + arr.join(" "));
            try {
                this.windowOpened.focus();
            } catch (e) {
                alert("Pleas allow popups for this site - Authentication with Eve-Esi will be done in an seperate wintow");
            }
            return false;
        },
        watchProgress: function () {
            var self = this;
            this.httpGet("user/authProgress", function (result) {
                if (result == "PENDING") {
                    setTimeout(self.watchProgress, 5000);
                    return;
                }
                if (result == "UNKNOWN") {
                    console.log("progress unknown.. reloading anyways");
                }
                self.$store.dispatch('characters/loadUsers');
                if (self.windowOpened) {
                    self.windowOpened.close();
                    self.windowOpened = false;
                }
            });
        },
        checkAll: function (ev) {
            Array.from(ev.currentTarget.parentElement.getElementsByTagName("input")).forEach((i) => {
                i.checked = true;
            })
        }
    },
    mounted() {
        var $this = this;
        this.eventRoute = EventBus.on("EmptyClick", () => {
            $this.open = false;
            $this.loginOpen = false;
        })
    },
    unmounted() {
        if (this.eventRoute)
            EventBus.off(this.eventRoute);
    }
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
@import "@/scss/_variables.scss";

#characters {
    z-index: 199;
    text-align: center;
    position: fixed;
    top: 0px;
    right: 0px;
    display: grid;
    justify-items: center;
    overflow: hidden;
    transition: max-height .4s, width .4s, min-height .4s;
    background-color: #111;
    border: 5px solid #111;
    border-bottom-left-radius: 10px;

    img {
        height: 128px;
        width: 128px;
        transition: width .4s, height .4s;
    }

    .chrWidget {
        cursor: pointer;
        position: relative;
        overflow: hidden;
        display: inline-block;
    }

    .chrWidget.main {
        cursor: default;

        .promoteCharacterButton {
            display: none !important;
        }
    }

    .chrName {
        color: #f9f9f9;
        position: absolute;
        bottom: 5px;
        left: 0px;
        right: 0px;
        overflow-wrap: anywhere;
        text-align: center;
        display: none;
    }

    .characterButton {
        position: absolute;
        cursor: pointer;
        background: #445;
        border: 5px #445 solid;
        border-radius: 10px;
        box-shadow: 0px 0px 5px white;
        display: none;
    }

    .removeCharacterButton {
        right: 1px;
        top: 1px;
        color: #d3bb3a;
    }

    .promoteCharacterButton {
        left: 1px;
        top: 1px;
        color: #31bb3a;
    }

    .chrName {
        font-size: 13px;
        text-shadow: -1px -1px 2px #000, 1px -1px 2px #000, -1px 1px 2px #000, 1px 1px 2px #000;
    }

    .characterButton {
        font-size: 10px;
        height: 10px;
    }

    .mo .onHover {
        display: block;
    }
}

#characters.open {
    max-height: 2048px;
}

#addCharacter {
    position: absolute;
    background: $c-background;
    border: $c-border solid 2px;
    border-radius: 15px;
    padding: 15px;
    width: 195px;
    right: 170px;
    xleft: 45vw;
    z-index: 200;

    ul {
        list-style: none;
        padding: 0;

        li {
            display: grid;
            grid-template-columns: 25px 1fr;
            align-items: center;
            justify-items: center;
        }
    }


}


/* --------------------------------------------  BREAKPOINTS ------------------------------------------*/
@media screen and (max-width: 415px) {
    #characters {
        ul {
            font-size: 10px;
        }
    }
}


@media only screen and (min-width: 1280px) {
    #characters {
        .spinnerContainer {
            height: 128px;
            width: 128px;
            position: absolute;
            top: 5px;
        }
    }


}



@media only screen and (max-width: 600px) {
    #characters {
        width: 90px;
        min-height: 90px;
        max-height: 90px;

        img {
            width: 90px;
            height: 90px;
        }
    }

    #characters.open {

        .chrName,
        .characterButton {
            display: block;
        }
    }
}

@media only screen and (max-width: 1090px) {
    #characters {
        width: 90px;
        min-height: 90px;
        max-height: 90px;

        img {
            width: 90px;
            height: 90px;
        }
    }
}

@media only screen and (min-width: 1090px) {
    #characters {
        width: 195px;
        min-height: 129px;
        max-height: 129px;

        .chrWidget {
            height: 128px;
            width: 128px;
        }

        .chrWidget.mo .characterButton,
        .chrWidget.mo .chrName {
            display: block;
        }
    }
}</style>

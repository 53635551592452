import { createApp } from 'vue'
import App from './App.vue'
import store from './store'
import router from './router'
import request from './plugin/request.js'
import axios from './axios.js'
import VueAxios from 'vue-axios'
import { createHead, VueHeadMixin } from "@unhead/vue"

const app = createApp(App);
const head = createHead();

window.app = app;
app.use(request);
app.use(VueAxios, axios);

app.use(store);
app.use(router);
app.mixin(VueHeadMixin);
app.use(head);

app.mount('#app');

export default app;
/*
window.app = new Vue({
  router,
  store,
  render: h => h(App),
  created() {
      Vue.httpSetStore(this.$store);
  }
}).$mount('#app')
*/
import axios from '../axios.js'

const shop = {
    namespaced: true,
    state: () => ({
        "containerList": [],
        "charName": ""
    }),
    mutations: {
        SAVE_CONTAINERLIST(state, containerList) {
            state.containerList = containerList.map((con) => {
                con.items=false;
                con.cart = [];
                return con;
            });
        },
        SAVE_CONTAINER(state, data) {
            var container = state.containerList.filter((con) => {
                return con.coni == data.conId;
            })[0];
            if (!container) return;
            container.items = data.items;
        },
        SAVE_CHAR_NAME(state, name) {
            state.charName = name;
        },
        ADD_TO_CART(state, position) {
            var container = state.containerList.filter((con) => {
                return con.coni == position.coni;
            })[0];
            if (!container) return;
            let inCart = container.cart.filter((itm) => {
                return itm.casId == position.casId;
            })[0];
            if (inCart) {
                inCart.qty = position.qty;
                return;
            }
            container.cart.push({
                casId: position.casId,
                qty: position.qty,
                item: position.item,
                price: position.price
            });
        },
        REMOVE_FROM_CART(state, position) {
            var container = state.containerList.filter((con) => {
                return con.coni == position.coni;
            })[0];
            if (!container) return;
            container.cart = container.cart.filter((itm) => {
                return itm.casId != position.casId;
            });
        },
        EMPTY_CART(state) {
            state.containerList.forEach((con) => {
                con.cart = [];
            });
        }
    },
    actions: {
        loadAll({commit, dispatch}, chrName) {
            return new Promise(
                (resolve, reject) => {
                    dispatch('globalState/setLoading', { which: "Assets", bol: true}, {root: true});
                    axios.get('shop/' + chrName).then(result => {
                        commit('SAVE_CONTAINERLIST', result.data);
                        commit('SAVE_CHAR_NAME', chrName);
                        let promises = [];
                        result.data.forEach((cont) => {
                            promises.push(dispatch('loadContainer', cont.coni));
                        });
                        Promise.all(promises).then(() => {
                            dispatch('globalState/setLoading', { which: "Assets", bol: false }, { root: true });
                            resolve();
                        })
                    }).catch(() => {
                        dispatch('globalState/setLoading', { which: "Assets", bol: false }, { root: true });
                    });
                });
        },
        loadContainer({commit, state}, id) {
            return new Promise(
                (resolve, reject) => {
                    axios.get('shop/contents/' + id).then(result => {
                        commit("SAVE_CONTAINER", result.data);
                        resolve();
                    })
                }
            )
        },
        addToCart({commit, state}, position) {
            commit("ADD_TO_CART", position);
        },
        removeFromCart({commit, state}, position) {
            commit("REMOVE_FROM_CART", position);
        },
        sendOrder({commit, dispatch, state}) {
            var result = state.containerList.reduce((prev, curr) => {
                prev[curr.coni] = curr.cart.map((pos) => {
                    return {
                        casId: pos.casId,
                        price: pos.price,
                        qty: pos.qty
                    };
                });
                return prev;
            }, {});
            this._vm.$httpPost('shop/order', {
                data: JSON.stringify(result)
            }).then(() => {
                dispatch('globalState/addMessage', {
                    message: "Order submitted"
                }, { root: true });
                commit("EMPTY_CART");
            })
        }
    }
}

export default shop

<template>
  <span class="chrWidget" @mouseover="hover = true" @mouseleave="hover = false" :class="{ mo: hover, main: character.main }">
      <img v-if="!isNaN(character.id)" :src="'https://images.evetech.net/characters/' + character.id + '/portrait?size=128'" />
      <span class="chrName onHover">{{ character.name }}</span>
      <span class="removeCharacterButton onHover characterButton" @click="removeCharacter">X</span>
      <span class="promoteCharacterButton onHover characterButton" @click="promoteCharacter">^</span>
  </span>
</template>

<script>
export default {
  name: 'Character',
  props: ['character'],
  data() {
      return {
          hover: false
      };
  },
  methods: {
      removeCharacter: function() {
          this.$store.dispatch("characters/removeCharacter", this.character.id);
      },
      promoteCharacter: function() {
          this.$store.dispatch("characters/promoteCharacter", this.character.id);
      }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">

</style>

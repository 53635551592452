const Skills = function() {
    this.skills = [
        {
            'name': 'Reprocessing',
            'type': 'Refining',
            'ore': [3,6,9,12,15],
            'ice': [3,6,9,12,15],
            'oreType': '*'
        },
        {
            'name': 'Reprocessing Efficiency',
            'type': 'Refining',
            'ore': [2,4,6,8,10],
            'ice': [2,4,6,8,10],
            'oreType': '*'
        },
        {
            'name': 'Abyssal Ore Processing',
            'type': 'Refining',
            'ore': [2,4,6,8,10],
            'oreType': ['Bezdnacine', 'Rakovene', 'Talassonite']
        },
        {
            'name': 'Mercoxit Ore Processing',
            'type': 'Refining',
            'ore': [2,4,6,8,10],
            'oreType': ['Mercoxit']
        },
        {
            'name': 'Rare Moon Ore Processing',
            'type': 'Refining',
            'ore': [2,4,6,8,10],
            'oreType': ['Pollucite', 'Cinnabar']
        },
        {
            'name': 'Coherent Ore Processing',
            'type': 'Refining',
            'ore': [2,4,6,8,10],
            'oreType': ['Hedbergite', 'Hemorphite', 'Jaspert', 'Kernite', 'Omber']
        },
        {
            'name': 'Common Moon Ore Processing',
            'type': 'Refining',
            'ore': [2,4,6,8,10],
            'oreType': ['Cobaltite', 'Euxenite', 'Titanite', 'Scheelite']
        },
        {
            'name': 'Complex Ore Processing',
            'type': 'Refining',
            'ore': [2,4,6,8,10],
            'oreType': ['Arkonor', 'Bistot', 'Spodumain']
        },
        {
            'name': 'Exceptional Moon Ore Processing',
            'type': 'Refining',
            'ore': [2,4,6,8,10],
            'oreType': ['Loparite', 'Ytterbite']
        },
        {
            'name': 'Scrapmetal Processing',
            'type': 'Refining',
            'scrapmetal': [2,4,6,8,10]
        },
        {
            'name': 'Simple Ore Processing',
            'type': 'Refining',
            'ore': [2,4,6,8,10],
            'oreType': ['Plagioclase', 'Pyroxeres', 'Scordite', 'Veldspar']
        },
        {
            'name': 'Ubiquitous Moon Ore Processing',
            'type': 'Refining',
            'ore': [2,4,6,8,10],
            'oreType': ['Zeolites', 'Sylvite', 'Bitumens', 'Coesite']
        },
        {
            'name': 'Uncommon Moon Ore Processing',
            'type': 'Refining',
            'ore': [2,4,6,8,10],
            'oreType': ['Otavite', 'Sperrylite', 'Vanadinite', 'Chromite']
        },
        {
            'name': 'Variegated Ore Processing',
            'type': 'Refining',
            'ore': [2,4,6,8,10],
            'oreType': ['Crokite', 'Dark Ochre', 'Gneiss']
        },
        {
            'name': 'Ice Processing',
            'type': 'Refining',
            'ice': [2,4,6,8,10]
        },
        {
            'name': 'Advanced Industrial Ship Construction',
            'type': 'Manufacturing',
            'time': [1,2,3,4,5]
        },
        {
            'name': 'Advanced Industry',
            'type': 'Manufacturing',
            'time': [1,2,3,4,5]
        },
        {
            'name': 'Advanced Large Ship Construction',
            'type': 'Manufacturing',
            'time': [1,2,3,4,5]
        },
        {
            'name': 'Advanced Medium Ship Construction',
            'type': 'Manufacturing',
            'time': [1,2,3,4,5]
        },
        {
            'name': 'Advanced Small Ship Construction',
            'type': 'Manufacturing',
            'time': [1,2,3,4,5]
        },
        {
            'name': 'Industry',
            'type': 'Manufacturing',
            'time': [4,8,12,16,20]
        }
    ]
};

Skills.prototype.getManufacturing = function() {
    return this.skills.filter((s) => {
        return s.type == "Manufacturing"
    }).sort((a, b) => {
        if (a.name == b.name) return 0;
        if (a.name < b.name) return -1;
        return 1;
    });
};

Skills.prototype.getRefining = function() {
    return this.skills.filter((s) => {
        return s.type == "Refining"
    })
};

Skills.prototype.getByName = function(name) {
    return this.skills.find(s => s.name == name);
};

export default (new Skills());

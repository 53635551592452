export const estimatedItemValue = function(mats) {
    if (!mats || mats.length < 1) {
        throw "Missing item (no mats)";
    }
    return mats.reduce((prev, mat) => {
        var i = mat.item || mat.i;
        if (!i || (i.adjustedPrice == undefined && i.basePrice == undefined)) {
            throw "Missing item";
        }
        var quantity = mat.quantity || mat.q || 1;
        return prev + (quantity * (i.adjustedPrice || i.basePrice));
    }, 0);
}

export const manufacturingJobCost = function(mats, costIndizes, settings) {
    let eiv = estimatedItemValue(mats);
    return Math.ceil(eiv * costIndizes.manufacturing * (1 - settings.engineering.station.fee/100) * (1 + settings.engineering.tax/100));
};

export const neededRuns = function(neededAmount, producedAmount) {
    if (!producedAmount) {
        return neededAmount;
    }
    return Math.ceil(neededAmount / producedAmount);
}

export const calculateBrokerFee = function(brokerRelationsLevel, facStanding, corpStanding) {
    return 3 - (0.3 * brokerRelationsLevel) - (0.3 * facStanding) - (0.2 * corpStanding);
}

export const calculateSaleTotal = function(price, tax, broker) {
    let salesTax = price * (tax / 100);
    let brokerFee = price * ((broker || 0) / 100);
    return Math.round((price - salesTax - brokerFee) * 100) / 100;
}

export const calculateProductionTime = function(time, skillNimps, structBonus, TE) {
    let teff = TE || 0;
    let t = time - time * (teff / 100);
    t = time - time * (skillNimps / 100);
    t = t - t * (structBonus / 100);
    return t;
}

export const calculateMEmats = function(amount, settings, runs, ME) {
    let result = amount * runs;
    result = result * (1 - (ME || settings.engineering.ME) / 100);
    result = result * (1 - settings.engineering.station.mat / 100);
    return Math.max(runs, Math.ceil(result));
}

import axios from '../axios.js'

const setProduce = function(el, val) {
    if (!el.cost) {
        return;
    }
    el.produce = false;
    el.cost.forEach(function(sel) {
        setProduce(sel, val);
    });
}


const productionCost = {
    namespaced: true,
    state: () => ({
        item: {},
        list: {},
        loading: false
    }),
    mutations: {
        SAVE_PRODUCTION_COST(state, {item, prodCost}) {
            state.item = item;
            setProduce(prodCost, false);
            prodCost.produce = true;
            state.list = prodCost;
        },
        UPDATE_SYSTEM_AND_COST_INDEX(state, {sc, sn}) {
            state.list.ssn = sn;
            state.list.costIndizes = sc;
        }
    },
    actions: {
        loadSCI ({commit}, val) {
            return new Promise((resolve, reject) => {
                axios.get('market/costIndices/' + val.scid).then(function(result) {
                    commit('UPDATE_SYSTEM_AND_COST_INDEX', {sc: result.data, sn: val.name});
                    resolve(true);
                }).catch((error) => {
                    reject(error);
                });
            })
        },
        load ({commit, dispatch}, item) {
            if (this.item && item.id == this.item.id) {
                return;
            }
            dispatch('globalState/setLoading', {which: "ProductionCost", bol: true}, {root: true});
            axios.get('market/production/cost/' + item.id).then(result => {
                commit('SAVE_PRODUCTION_COST', { item: item, prodCost: result.data});
            }).catch((error) => {
                if (error.response) {
                    dispatch("globalState/addMessage", {
                        type: "error",
                        message: "Server responded with status-code " + error.response.status,
                        details: error
                    }, { root: true });
                } else if (error.request) {
                    dispatch("globalState/addMessage", {
                        type: "error",
                        message: "No response from Server",
                        details: error
                    }, { root: true });
                } else {
                    dispatch("globalState/addMessage", {
                        type: "error",
                        message: "Failed axios request",
                        details: error
                    }, { root: true });
                }
            }).then(() => {
                dispatch('globalState/setLoading', {which: "ProductionCost", bol: false}, {root: true});
            });
        }
    }
}

export default productionCost
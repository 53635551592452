
const AxiosHelper = function() {

}

AxiosHelper.prototype.httpPost = function(url, params, callback, finCallback) {
    const store = this.config.globalProperties.$store;
    if (!store) {
        throw "Not initialized";
    }
    let p = this.axios.post(url, params);
    if (callback) {
        p.then(result => {
            callback(result.data);
        });
    }
    p.catch((error) => {
        console.log(JSON.stringify(error));
        if (error.response) {
            store.dispatch("globalState/addMessage", {
                type: "error",
                message: "Server responded with status-code " + error.response.status,
                details: error.response.data,
                url: url
            });
        } else if (error.request) {
            store.dispatch("globalState/addMessage", {
                type: "error",
                message: "No response from Server",
                details: error,
                url: url
            });
        } else {
            store.dispatch("globalState/addMessage", {
                type: "error",
                message: "Failed axios request",
                details: error,
                url: url
            });
        }
    }).then(finCallback);
    return p;
};

AxiosHelper.prototype.httpGet = function(url, callback, finCallback, params) {
    const store = this.config.globalProperties.$store;
    if (!store) {
        throw "Not initialized";
    }
    let p = this.axios.get(url, params);
    if (callback) {
        p.then(result => {
            callback(result.data);
        })
    }
    p.catch((error) => {
        console.log(JSON.stringify(error));
        if (error.response) {
            store.dispatch("globalState/addMessage", {
                type: "error",
                message: "Server responded with status-code " + error.response.status,
                details: error.response.data,
                url: url
            });
        } else if (error.request) {
            store.dispatch("globalState/addMessage", {
                type: "error",
                message: "No response from Server",
                details: error,
                url: url
            });
        } else {
            store.dispatch("globalState/addMessage", {
                type: "error",
                message: "Failed axios request",
                details: error,
                url: url
            });
        }
    }).then(finCallback);
    return p;
};

AxiosHelper.prototype.install = function(app, options) {
    this.app = app;
    this.options = options;
    app.config.globalProperties.httpGet = this.httpGet.bind(app);
    app.config.globalProperties.httpPost = this.httpPost.bind(app);
}

const ah = new AxiosHelper();

export default ah;

<template>
    <span v-html="svgContent" :class="{loading: svg.list['loading'] == svgContent}" />
</template>

<script>
import { mapState, mapGetters } from 'vuex'

export default {
    name: 'SvgAtom',
    props: ['name', 'alt'],
    computed: {
        ...mapState(['svg']),
        svgContent() {
            if (!this.$store.state.svg.list[this.name]) {
                this.$store.dispatch('svg/load', this.name);
            }
            if (this.alt) {
                return this.$store.state.svg.list[this.name].replace(/^(<svg[^>]+>)/i, '$1<title>' + this.alt + '</title>');
            }
            return this.$store.state.svg.list[this.name]
        }
    },
    created() {
        if (!this.$store.state.svg.list[this.name]) {
            this.$store.dispatch('svg/load', this.name);
        }
    }
}
</script>
<style lang="scss">
@import "@/scss/_variables.scss";

@keyframes loading {
    0%      {transform: rotate(0deg)}
    12.5%   {transform: rotate(45deg)}
    25%     {transform: rotate(90deg)}
    37.5%   {transform: rotate(135deg)}
    50%     {transform: rotate(180deg)}
    62.5%   {transform: rotate(225deg)}
    75%     {transform: rotate(270deg)}
    87.5%   {transform: rotate(315deg)}
    100%    {transform: rotate(360deg)}
}
.loading svg {
    max-width: 20px;
    max-height: 20px;
    animation-name: loading;
    animation-duration: 1s;
    animation-iteration-count: infinite;
    fill: $c-highlight;
}
</style>